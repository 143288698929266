import { EntityLink } from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';
import { IconComponent, useApp } from '@backstage/core-plugin-api';
import LanguageIcon from '@mui/icons-material/Language';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';

const WebLink = ({
  href,
  Icon,
  text,
}: {
  href: string;
  text?: string;
  Icon?: IconComponent;
}) => (
  <ListItem key={href}>
    <ListItemIcon>{Icon ? <Icon /> : <LanguageIcon />}</ListItemIcon>
    <ListItemText>
      <Link to={href}>{text}</Link>
    </ListItemText>
  </ListItem>
);

export const LinksGroup = ({ links }: { links?: EntityLink[] }) => {
  const app = useApp();
  const iconResolver = (key?: string) =>
    key ? app.getSystemIcon(key) ?? LanguageIcon : LanguageIcon;

  if (links === undefined) {
    return null;
  }

  return (
    <>
      <Divider />
      {links.map(link => {
        return (
          <WebLink
            key={link.url}
            href={link.url}
            text={link.title}
            // @ts-expect-error blocked by mui v5 migration of backstage internals
            Icon={iconResolver(link.icon)}
          />
        );
      })}
    </>
  );
};
