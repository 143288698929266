export const registrySchema = {
  $defs: {
    AIBox: {
      properties: {
        kind: {
          const: 'ai_box',
          default: 'ai_box',
          description: 'AIBox Resource Kind',
          title: 'AIBox Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'AIBox Resource Name',
          title: 'AIBox Resource Name',
          type: 'string',
        },
      },
      required: ['Name'],
      title: 'AIBox',
      type: 'object',
    },
    AIGatewayConfig: {
      properties: {
        userGroup: {
          description:
            'AAD object ID for the consumer group of the AI Gateway.',
          format: 'uuid',
          title: 'User group',
          type: 'string',
          'ui:field': 'AzureGroupSearch',
          'vsc:picker': 'aad-group',
        },
      },
      required: ['userGroup'],
      title: 'AIGatewayConfig',
      type: 'object',
    },
    AISearchLocation: {
      description:
        'The Azure Search service location to be used, see the [documentation] for details.\nWe provide only a feature complete subset of the available locations.\n[documentation]: https://learn.microsoft.com/en-us/azure/search/search-region-support',
      enum: ['northeurope', 'switzerlandnorth', 'westeurope'],
      title: 'AISearchLocation',
      type: 'string',
    },
    AISearchServiceConfigBasic: {
      properties: {
        kind: {
          const: 'ai-search-basic',
          default: 'ai-search-basic',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        sku: {
          $ref: '#/$defs/AISearchSku',
          default: 'basic',
        },
        location: {
          $ref: '#/$defs/AISearchLocation',
          default: 'northeurope',
          description:
            'Azure region where search service should be provisioned.',
          title: 'Location',
        },
        semanticRankerEnabled: {
          default: false,
          description: 'Enable semantic ranking',
          title: 'Semantic ranking',
          type: 'boolean',
        },
        externalAccess: {
          default: true,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
        uniqueIdentifier: {
          default: '',
          description:
            'Add unique identifier to support multiple instances of a module.',
          title: 'Unique Identifier',
          type: 'string',
        },
        replicaCount: {
          default: 1,
          description: 'Number of replicas for the Azure Search service.',
          maximum: 3,
          title: 'Replica count',
          type: 'integer',
        },
        partitionCount: {
          anyOf: [
            {
              enum: [1, 2, 3],
              type: 'integer',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Number of partitions for the Azure Search service.',
          title: 'Partition count',
        },
      },
      required: ['kind'],
      title: 'AISearchServiceConfigBasic',
      type: 'object',
    },
    AISearchServiceConfigStandard: {
      properties: {
        kind: {
          const: 'ai-search',
          default: 'ai-search',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        sku: {
          $ref: '#/$defs/AISearchSku',
          default: 'standard',
        },
        location: {
          $ref: '#/$defs/AISearchLocation',
          default: 'northeurope',
          description:
            'Azure region where search service should be provisioned.',
          title: 'Location',
        },
        semanticRankerEnabled: {
          default: false,
          description: 'Enable semantic ranking',
          title: 'Semantic ranking',
          type: 'boolean',
        },
        externalAccess: {
          default: true,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
        uniqueIdentifier: {
          default: '',
          description:
            'Add unique identifier to support multiple instances of a module.',
          title: 'Unique Identifier',
          type: 'string',
        },
        replicaCount: {
          default: 1,
          description: 'Number of replicas for the Azure Search service.',
          maximum: 12,
          title: 'Replica count',
          type: 'integer',
        },
        partitionCount: {
          anyOf: [
            {
              enum: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12],
              type: 'integer',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Number of partitions for the Azure Search service.',
          title: 'Partition count',
        },
      },
      required: ['kind'],
      title: 'AISearchServiceConfigStandard',
      type: 'object',
    },
    AISearchSku: {
      description:
        'The Azure Search service SKU name to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/search/search-sku-tier',
      enum: ['basic', 'standard'],
      title: 'AISearchSku',
      type: 'string',
    },
    AIServices: {
      properties: {
        kind: {
          const: 'ai_services',
          default: 'ai_services',
          description: 'AIServices Resource Kind',
          title: 'AIServices Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'AIServices Resource Name',
          title: 'AIServices Resource Name',
          type: 'string',
        },
      },
      required: ['Name'],
      title: 'AIServices',
      type: 'object',
    },
    AadGroups: {
      properties: {
        Dataengineer: {
          anyOf: [
            {
              $ref: '#/$defs/Group',
            },
            {
              type: 'null',
            },
          ],
          description: 'Configuration details for the Data Engineer group.',
          title: 'Data Engineer Group',
        },
        User: {
          anyOf: [
            {
              $ref: '#/$defs/Group',
            },
            {
              type: 'null',
            },
          ],
          description: 'Configuration details for the User group.',
          title: 'User Group',
        },
        Security: {
          anyOf: [
            {
              $ref: '#/$defs/Group',
            },
            {
              type: 'null',
            },
          ],
          description: 'Configuration details for the Security group.',
          title: 'Security Group',
        },
        Custom: {
          default: [],
          description: 'A list of custom groups with specific configurations.',
          items: {
            $ref: '#/$defs/Group',
          },
          title: 'Custom Groups',
          type: 'array',
        },
      },
      required: ['Dataengineer', 'User', 'Security'],
      title: 'AadGroups',
      type: 'object',
    },
    AcrResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'acr',
          default: 'acr',
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the ACR is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        sku: {
          description: 'The SKU of the ACR.',
          title: 'SKU',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'sku', 'resourceId'],
      title: 'AcrResource',
      type: 'object',
    },
    Adf: {
      properties: {
        kind: {
          const: 'adf',
          default: 'adf',
          description: 'ADF Resource Kind',
          title: 'ADF Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'ADF Resource Name',
          title: 'ADF Resource Name',
          type: 'string',
        },
        Repo: {
          default: 0,
          description: '# of repos for resource',
          maximum: 10,
          minimum: 0,
          title: '# of repos for resource',
          type: 'integer',
        },
        RepoFork: {
          default: false,
          description: 'Repo Fork option enabled',
          title: 'Repo Fork option enabled',
          type: 'boolean',
        },
        Vsts: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'vsts for ADF resource',
          title: 'vsts for ADF resource',
        },
        DiagnosticSettingsLaw: {
          default: false,
          description: 'Diagnostic Settings Law',
          title: 'Diagnostic Settings Law',
          type: 'boolean',
        },
        DiagnosticSettingsSta: {
          default: false,
          description: 'Diagnostic Settings Sta',
          title: 'Diagnostic Settings Sta',
          type: 'boolean',
        },
        ManagedVirtualNetworkEnabled: {
          default: false,
          description: 'Managed VN Enabled',
          title: 'Managed VN Enabled',
          type: 'boolean',
        },
        Logs: {
          default: [],
          description: 'Logs for ADF resource',
          items: {
            type: 'string',
          },
          title: 'Logs for ADF resource',
          type: 'array',
        },
        Metrics: {
          default: [],
          description: 'Metrics for ADF resource',
          items: {
            type: 'string',
          },
          title: 'Metrics for ADF resource',
          type: 'array',
        },
      },
      required: ['Name'],
      title: 'Adf',
      type: 'object',
    },
    Adls: {
      properties: {
        kind: {
          const: 'adls',
          default: 'adls',
          description: 'Adls Resource Kind',
          title: 'Adls Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'Adls Resource Name',
          title: 'Adls Resource Name',
          type: 'string',
        },
      },
      required: ['Name'],
      title: 'Adls',
      type: 'object',
    },
    AdxBackendConfig: {
      properties: {
        enabled: {
          default: false,
          title: 'Enable Azure Data Explorer.',
          type: 'boolean',
        },
        location: {
          $ref: '#/$defs/AzureRegion',
          default: 'westeurope',
          description: 'Region where ADX cluster is deployed.',
          title: 'Azure region',
        },
        sku: {
          $ref: '#/$defs/AdxSku',
          default: 'Dev(No SLA)_Standard_D11_v2',
          description: 'The SKU of the ADX cluster.',
          title: 'SKU',
        },
      },
      title: 'AdxBackendConfig',
      type: 'object',
    },
    AdxSku: {
      enum: ['Dev(No SLA)_Standard_D11_v2', 'Dev(No SLA)_Standard_E2a_v4'],
      title: 'AdxSku',
      type: 'string',
    },
    Aml: {
      properties: {
        kind: {
          const: 'aml',
          default: 'aml',
          description: 'Aml Resource Kind',
          title: 'Aml Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'Aml Resource Name',
          title: 'Aml Resource Name',
          type: 'string',
        },
        Repo: {
          default: 0,
          description: '# of repos for resource',
          maximum: 10,
          minimum: 0,
          title: '# of repos for resource',
          type: 'integer',
        },
        RepoFork: {
          default: false,
          description: 'Repo Fork option enabled',
          title: 'Repo Fork option enabled',
          type: 'boolean',
        },
      },
      required: ['Name'],
      title: 'Aml',
      type: 'object',
    },
    Annotations: {
      properties: {
        'devops.space': {
          description: 'The DevOps space associated with the metadata',
          title: 'DevOps Space',
          type: 'string',
        },
      },
      required: ['devops.space'],
      title: 'Annotations',
      type: 'object',
    },
    AppStoreDeployment: {
      properties: {
        name: {
          description: 'Name of the appstore app to be linked',
          title: 'App name',
          type: 'string',
        },
      },
      required: ['name'],
      title: 'AppStoreDeployment',
      type: 'object',
    },
    ApplicationConfig: {
      properties: {
        requiredResourceAccess: {
          description: 'Additional permissions on the product application.',
          items: {
            discriminator: {
              mapping: {
                'microsoft-graph':
                  '#/$defs/ResourceAccessConfig_Literal_MS_GRAPH__MsGraphPermission_',
              },
              propertyName: 'resource',
            },
            oneOf: [
              {
                $ref: '#/$defs/ResourceAccessConfig_Literal_MS_GRAPH__MsGraphPermission_',
              },
            ],
          },
          title: 'Additional resource accesses',
          type: 'array',
        },
        extraRedirectUrls: {
          additionalProperties: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
          description: 'Additional callback URLs for the product application.',
          examples: [
            {
              development: ['http://localhost:3000'],
            },
          ],
          propertyNames: {
            $ref: '#/$defs/LogicalStage',
          },
          title: 'Extra callback URLs',
          type: 'object',
        },
      },
      title: 'ApplicationConfig',
      type: 'object',
    },
    ApplicationInfo: {
      properties: {
        kind: {
          const: 'application',
          default: 'application',
          title: 'Kind',
          type: 'string',
        },
        clientId: {
          title: 'Application client ID.',
          type: 'string',
        },
        objectId: {
          title: 'Application object ID.',
          type: 'string',
        },
        displayName: {
          title: 'Application display name.',
          type: 'string',
        },
      },
      required: ['kind', 'clientId', 'objectId', 'displayName'],
      title: 'ApplicationInfo',
      type: 'object',
    },
    ArangoBackupPolicy: {
      description:
        'The BackupPolicy controls how Arango will create and retain backups.',
      properties: {
        enabled: {
          default: false,
          description:
            'Denotes if regular backups of data contained in arango are created.',
          title: 'Backups enabled.',
          type: 'boolean',
        },
        schedule: {
          default: '*/15 * * * *',
          description:
            'Defines the interval at which backups should be created.',
          pattern:
            '(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every ([0-9]+(ns|us|\u00b5s|ms|s|m|h))+)|(((([0-9]+,)+[0-9]+|([0-9]+(/|-)[0-9]+)|[0-9]+|\\*) ?){5,7})',
          title: 'Backup schedule',
          type: 'string',
        },
        maxBackups: {
          default: 10,
          description: 'Maximum number of backups that will be kept.',
          title: 'Maximum backups',
          type: 'integer',
        },
      },
      title: 'ArangoBackupPolicy',
      type: 'object',
    },
    ArangoConfig: {
      description:
        'Scalable graph database for graph, document, and search functionality.',
      properties: {
        kind: {
          const: 'arango',
          default: 'arango',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        operatorImageVersion: {
          default: '1.2.36',
          title: 'ArangoDb operator image version',
          type: 'string',
        },
        imageVersion: {
          default: '3.11.4',
          title: 'ArangoDb image version',
          type: 'string',
        },
        deploymentChartVersion: {
          default: '1.3.1',
          title: 'Arango deployment Helm chart version',
          type: 'string',
        },
        mode: {
          $ref: '#/$defs/Mode',
          default: 'Single',
          description:
            'Controls if the database is deployed as single instance or cluster.',
          title: 'Single instance or Cluster mode.',
        },
        metrics: {
          $ref: '#/$defs/ArangoMetrics',
          default: {
            enabled: false,
          },
          description:
            'Configuration on how metrics are emitted and collected.',
          title: 'Metrics configuration.',
        },
        backupPolicy: {
          $ref: '#/$defs/ArangoBackupPolicy',
          default: {
            enabled: false,
            schedule: '*/15 * * * *',
            maxBackups: 10,
          },
          description:
            'The backup policy controls creation and retention of database backups.',
          title: 'Backup policy',
        },
        resources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '1',
              memory: '1Gi',
            },
            limits: {
              cpu: '3',
              memory: '5Gi',
            },
          },
          description: 'Resource request and limit for the Arango workload.',
          title: 'Arango resources',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the Arango UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
      },
      required: ['kind'],
      title: 'ArangoConfig',
      type: 'object',
    },
    ArangoMetrics: {
      description: 'Metrics configuration controls how metrics are collected.',
      properties: {
        enabled: {
          default: false,
          description:
            'Denotes if metrics for the Arango deployment are collected.',
          title: 'Metrics enabled.',
          type: 'boolean',
        },
      },
      title: 'ArangoMetrics',
      type: 'object',
    },
    ArtifactType: {
      description: 'Artifact type.',
      enum: ['python-library', 'npm-library', 'oci', 'workload', 'techdocs'],
      title: 'ArtifactType',
      type: 'string',
    },
    AzureAIServicesConfig: {
      properties: {
        kind: {
          const: 'ai-multi-service',
          default: 'ai-multi-service',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        externalAccess: {
          default: true,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
      },
      required: ['kind'],
      title: 'AzureAIServicesConfig',
      type: 'object',
    },
    AzureDevOpsProject: {
      description:
        'Configuration for an Azure DevOps project integrated with the Argus platform.',
      properties: {
        name: {
          title: 'Name',
          type: 'string',
        },
        organization: {
          default: 'basf4dev',
          description:
            'The Azure DevOps organization where the project resides.',
          title: 'Azure DevOps organization',
          type: 'string',
        },
      },
      required: ['name'],
      title: 'AzureDevOpsProject',
      type: 'object',
    },
    AzureRegion: {
      enum: ['westeurope'],
      title: 'AzureRegion',
      type: 'string',
    },
    BackendRef: {
      description:
        'BackendRef is a reference to a backend to forward matched requests to.',
      properties: {
        name: {
          description: 'Name of the backend.',
          title: 'Name',
          type: 'string',
        },
        namespace: {
          description: 'Namespace of the backend.',
          title: 'Namespace',
          type: 'string',
        },
        port: {
          description: 'Port of the backend.',
          title: 'Port',
          type: 'integer',
        },
      },
      required: ['name', 'namespace', 'port'],
      title: 'BackendRef',
      type: 'object',
    },
    BaseResourceInfo: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
      },
      required: ['resourceId'],
      title: 'BaseResourceInfo',
      type: 'object',
    },
    CellClusterReport: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        caCertificate: {
          description:
            'Kubernetes API server certificate authority (base64 encoded)',
          title: 'CA certificate',
          type: 'string',
        },
        oidcIssuerUrl: {
          title: 'OIDC issuer URL',
          type: 'string',
        },
        apiServerUrl: {
          description: 'Base URL of the Kubernetes control plane',
          title: 'API server Url',
          type: 'string',
        },
        kubernetesVersion: {
          description: 'Version of k8s running on the cluster',
          title: 'Kubernetes version',
          type: 'string',
        },
      },
      required: [
        'resourceId',
        'caCertificate',
        'oidcIssuerUrl',
        'apiServerUrl',
        'kubernetesVersion',
      ],
      title: 'CellClusterReport',
      type: 'object',
    },
    CellSubscription: {
      properties: {
        identifier: {
          title: 'Identifier',
          type: 'string',
        },
        name: {
          title: 'Name',
          type: 'string',
        },
      },
      required: ['identifier', 'name'],
      title: 'CellSubscription',
      type: 'object',
    },
    CertManagerConfig: {
      properties: {
        config: {
          anyOf: [
            {
              $ref: '#/$defs/CertManagerConfigBase',
            },
            {
              $ref: '#/$defs/StagedConfigDict_CertManagerConfigBase_',
            },
          ],
          description: 'Configuration for the resource',
          title: 'Resource configuration',
        },
      },
      required: ['config'],
      title: 'CertManagerConfig',
      type: 'object',
    },
    CertManagerConfigBase: {
      properties: {
        enabled: {
          default: false,
          title: 'Enable cert manager.',
          type: 'boolean',
        },
        chart_version: {
          default: 'v1.16.1',
          title: 'The version of the cert-manager chart to install.',
          type: 'string',
        },
      },
      title: 'CertManagerConfigBase',
      type: 'object',
    },
    CloudBeaverConfig: {
      properties: {
        kind: {
          const: 'cloudbeaver',
          default: 'cloudbeaver',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        uiPathPrefix: {
          $ref: '#/$defs/UrlPathSegment',
          default: '/dbeaver',
          description: 'Route where the main UI is served.',
          title: 'UI path prefix',
        },
        chartVersion: {
          default: '1.3.0',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the Cloudbeaver UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
      },
      required: ['kind'],
      title: 'CloudBeaverConfig',
      type: 'object',
    },
    ClusterSetup: {
      properties: {
        certManager: {
          $ref: '#/$defs/CertManagerConfig',
          title: 'Cert manager configuration.',
        },
        opentelemetry: {
          $ref: '#/$defs/OpenTelemetryConfig',
          title: 'OpenTelemetry configuration.',
        },
      },
      title: 'ClusterSetup',
      type: 'object',
    },
    CodeLocation: {
      properties: {
        repository: {
          description: 'Repository name.',
          title: 'Repository',
          type: 'string',
        },
        artifacts: {
          description: 'List of artifacts.',
          items: {
            $ref: '#/$defs/ArtifactType',
          },
          title: 'Artifacts',
          type: 'array',
        },
        workflow: {
          $ref: '#/$defs/Workflow',
          default: 'github-flow',
        },
        ruleset: {
          $ref: '#/$defs/RuleSet',
          default: 'base',
          description: 'Ruleset to apply to the code location.',
          title: 'Ruleset',
        },
        deployment: {
          $ref: '#/$defs/DeploymentTargetLevel',
          default: 'L5',
          title: 'Deployment Target Level',
        },
      },
      required: ['repository', 'artifacts'],
      title: 'CodeLocation',
      type: 'object',
    },
    CognitiveAccountResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'cognitive-services',
          default: 'cognitive-services',
          title: 'Kind',
          type: 'string',
        },
        accountType: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'The type of the Cognitive Services account.',
          title: 'Account type',
        },
        location: {
          description: 'The location where the Cognitive Services is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        endpoint: {
          description: 'The FQDN of the Azure Cognitive Services.',
          title: 'Cognitive Services URI',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'endpoint', 'resourceId'],
      title: 'CognitiveAccountResource',
      type: 'object',
    },
    CognitiveDeploymentsResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'cognitive-deployment',
          default: 'cognitive-deployment',
          title: 'Kind',
          type: 'string',
        },
        name: {
          description: 'The name of the Cognitive Services deployment.',
          title: 'Deployment name',
          type: 'string',
        },
        cogModelName: {
          description: 'The name of the Cognitive Services model.',
          title: 'Model name',
          type: 'string',
        },
        cogModelVersion: {
          description: 'The version of the Cognitive Services model.',
          title: 'Model version',
          type: 'string',
        },
      },
      required: [
        'kind',
        'name',
        'cogModelName',
        'cogModelVersion',
        'resourceId',
      ],
      title: 'CognitiveDeploymentsResource',
      type: 'object',
    },
    CognitiveSearch: {
      properties: {
        kind: {
          const: 'cognitive_search',
          default: 'cognitive_search',
          description: 'CognitiveSearch Resource Kind',
          title: 'CognitiveSearch Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'CognitiveSearch Resource Name',
          title: 'CognitiveSearch Resource Name',
          type: 'string',
        },
      },
      required: ['Name'],
      title: 'CognitiveSearch',
      type: 'object',
    },
    CustomDomainReport: {
      properties: {
        nodeId: {
          description: 'The ID of the node in the cluster.',
          title: 'Node ID',
          type: 'string',
        },
        cert: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Name of the certificate',
        },
        kvId: {
          anyOf: [
            {
              description: 'Canonical reference to resource.',
              title: 'Azure resource id.',
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Keyvault Resource ID',
        },
        role: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The role of the cell within the platform instance.',
          title: 'Role',
        },
        url: {
          description: 'The URL of the domain.',
          title: 'URL',
          type: 'string',
        },
        flavour: {
          $ref: '#/$defs/Domain',
          description: 'The flavour of the domain.',
          title: 'Flavour',
        },
        product: {
          description: 'Name of the Product this URL is used for',
          title: 'Product Name',
          type: 'string',
        },
      },
      required: ['nodeId', 'role', 'url', 'flavour', 'product'],
      title: 'CustomDomainReport',
      type: 'object',
    },
    DagsterApplyLimitPerUniqueValue: {
      properties: {
        applyLimitPerUniqueValue: {
          title: 'Applylimitperuniquevalue',
          type: 'boolean',
        },
      },
      required: ['applyLimitPerUniqueValue'],
      title: 'DagsterApplyLimitPerUniqueValue',
      type: 'object',
    },
    DagsterConfig: {
      description:
        'Orchestrator for the whole development lifecycle, with integrated lineage and observability\n\nThe [Dagster] module provides an instance of the Dagster orchestrator to run\nyour data-engineering, or machine learning pipelines.\n\n[Dagster]: https://docs.dagster.io/\n\nNote:\n    This module will only provision the core orchestrator. Dagster code locations\n    where you define your pipelines are deployed separately.',
      properties: {
        kind: {
          const: 'dagster',
          default: 'dagster',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chartVersion: {
          default: '1.8.13',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        uiPathPrefix: {
          $ref: '#/$defs/UrlPathSegment',
          default: '/dagster',
          description: 'Route where the main UI is served.',
          title: 'UI path prefix',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the Dagster UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
        storeComputeLogsEnabled: {
          default: false,
          description: 'Compute logs in blob storage',
          title: 'Store compute logs',
          type: 'boolean',
        },
        userDeployments: {
          default: [],
          description: 'Code location servers.',
          items: {
            $ref: '#/$defs/DagsterUserDeployment',
          },
          title: 'Code locations',
          type: 'array',
          'vsc:action': 'array',
        },
        maxConcurrentRuns: {
          default: 10,
          description: 'Maximum number of concurrent job execution runs.',
          title: 'Maximum concurrent runs',
          type: 'integer',
        },
        tagConcurrencyLimits: {
          default: [],
          description:
            'List of tags and their corresponding concurrency limits.',
          items: {
            $ref: '#/$defs/DagsterTagConcurrencyLimits',
          },
          title: 'Tag based concurrency limits',
          type: 'array',
          'vsc:action': 'array',
        },
        defaultJobResources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '200m',
              memory: '512Mi',
            },
            limits: {
              cpu: '1000m',
              memory: '1Gi',
            },
          },
          description: 'Default resources applied to pipeline execution runs.',
          title: 'Default job resources',
        },
        extraHelmValues: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Extra values to pass to dagster helm chart',
          title: 'Extra helm values',
          type: 'object',
        },
      },
      required: ['kind'],
      title: 'DagsterConfig',
      type: 'object',
    },
    DagsterTagConcurrencyLimits: {
      properties: {
        key: {
          title: 'Job tag key',
          type: 'string',
        },
        value: {
          anyOf: [
            {
              type: 'string',
            },
            {
              $ref: '#/$defs/DagsterApplyLimitPerUniqueValue',
            },
            {
              type: 'null',
            },
          ],
          title: 'Job tag value',
        },
        limit: {
          title: 'Maximum number of concurrent runs',
          type: 'integer',
        },
      },
      required: ['key', 'value', 'limit'],
      title: 'DagsterTagConcurrencyLimits',
      type: 'object',
    },
    DagsterUserDeployment: {
      properties: {
        host: {
          $ref: '#/$defs/RFC1035Name',
          description: 'Host name of the code location server',
          title: 'Code location host',
        },
        port: {
          $ref: '#/$defs/PortNumber',
          default: 3030,
          description: 'Port number of the code location server',
          title: 'Code location port',
        },
      },
      required: ['host', 'port'],
      title: 'DagsterUserDeployment',
      type: 'object',
    },
    DataDogIntegrationInfo: {
      properties: {
        agent_object_id: {
          title: 'Agent Object Id',
          type: 'string',
        },
      },
      required: ['agent_object_id'],
      title: 'DataDogIntegrationInfo',
      type: 'object',
    },
    DatabaseName: {
      maxLength: 63,
      minLength: 1,
      pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
      type: 'string',
    },
    Databricks: {
      properties: {
        kind: {
          const: 'databricks',
          default: 'databricks',
          description: 'Databricks Resource Kind',
          title: 'Databricks Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'Databricks Resource Name',
          title: 'Databricks Resource Name',
          type: 'string',
        },
        NatGateway: {
          default: false,
          description: 'Enable the NAT Gateway in databricks',
          title: 'NAT Gateway',
          type: 'boolean',
        },
        Serverless: {
          default: false,
          description: 'Enable serverless for SQL Warehouse',
          title: 'Serverless for SQL Warehouse',
          type: 'boolean',
        },
        ServerlessConfiguration: {
          anyOf: [
            {
              $ref: '#/$defs/ServerlessConfiguration',
            },
            {
              type: 'null',
            },
          ],
          default: '',
          description: 'Serverless Configuration',
          title: 'Serverless Configuration',
        },
        DiagnosticSettingsLaw: {
          default: false,
          description: 'Diagnostic Settings Law',
          title: 'Diagnostic Settings Law',
          type: 'boolean',
        },
        DiagnosticSettingsSta: {
          default: false,
          description: 'Diagnostic Settings Sta',
          title: 'Diagnostic Settings Sta',
          type: 'boolean',
        },
        Logs: {
          default: [],
          description: 'Logs for databricks resource',
          items: {
            type: 'string',
          },
          title: 'Logs for databricks resource',
          type: 'array',
        },
        Metrics: {
          default: [],
          description: 'Metrics for databricks resource',
          items: {
            type: 'string',
          },
          title: 'Metrics for databricks resource',
          type: 'array',
        },
        Repo: {
          default: 0,
          description: '# of repos for resource',
          maximum: 10,
          minimum: 0,
          title: '# of repos for resource',
          type: 'integer',
        },
        RepoFork: {
          default: false,
          description: 'Repo Fork option enabled',
          title: 'Repo Fork option enabled',
          type: 'boolean',
        },
        MountingPoints: {
          default: false,
          description: 'Databricks mounting points enabled',
          title: 'Databricks mounting points enabled',
          type: 'boolean',
        },
        StorageCredential: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Databricks mounting points enabled',
          title: 'Unity Catalog Storage Credential',
        },
        DbksAccessConnectorName: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Azure Access Connector for databricks unity catalog',
          title: 'Unity Catalog Access Connector',
        },
        DbksAccessConnector: {
          default: false,
          description: 'Azure Access Connector for databricks unity catalog',
          title: 'Unity Catalog Access Connector',
          type: 'boolean',
        },
        UnityCatalog: {
          default: false,
          description: 'Unity Catalog feature',
          title: 'Unity Catalog feature',
          type: 'boolean',
        },
        Catalogs: {
          default: [],
          description: 'Unity Catalog list of catalogs',
          items: {
            $ref: '#/$defs/UcCatalog',
          },
          title: 'Unity Catalog list of catalogs',
          type: 'array',
        },
      },
      required: ['Name'],
      title: 'Databricks',
      type: 'object',
    },
    DatabricksIn: {
      properties: {
        kind: {
          const: 'databricks_in',
          default: 'databricks_in',
          description: 'Databricks In Resource Kind',
          title: 'Databricks In Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'The name of the Databricks workspace.',
          title: 'Workspace Name',
          type: 'string',
        },
        Subscription: {
          description: 'The subscription ID associated with the Databricks.',
          title: 'Subscription ID',
          type: 'string',
        },
        InPepSubnetName: {
          description: 'The name of the Public End Point (PEP) subnet.',
          title: 'PEP Subnet Name',
          type: 'string',
        },
        InPublicSubnetName: {
          description: 'The name of the public subnet.',
          title: 'Public Subnet Name',
          type: 'string',
        },
        InPrivateSubnetName: {
          description: 'The name of the private subnet.',
          title: 'Private Subnet Name',
          type: 'string',
        },
      },
      required: [
        'Name',
        'Subscription',
        'InPepSubnetName',
        'InPublicSubnetName',
        'InPrivateSubnetName',
      ],
      title: 'DatabricksIn',
      type: 'object',
    },
    DbksBindingType: {
      enum: ['BINDING_TYPE_READ_WRITE', 'BINDING_TYPE_READ_ONLY'],
      title: 'DbksBindingType',
      type: 'string',
    },
    DbksCatalogSource: {
      enum: ['DSP', 'EDL'],
      title: 'DbksCatalogSource',
      type: 'string',
    },
    DeployedInstanceLayout: {
      properties: {
        platformCell: {
          $ref: '#/$defs/InstanceCellRef',
          title: 'Platform cell',
        },
        productCells: {
          additionalProperties: {
            $ref: '#/$defs/InstanceCellRef',
          },
          propertyNames: {
            $ref: '#/$defs/LogicalStage',
          },
          title: 'Product cells',
          type: 'object',
        },
      },
      required: ['platformCell', 'productCells'],
      title: 'DeployedInstanceLayout',
      type: 'object',
    },
    DeploymentTarget: {
      properties: {
        serviceConnection: {
          title: 'Serviceconnection',
          type: 'string',
        },
        serviceConnectionId: {
          title: 'Serviceconnectionid',
          type: 'string',
        },
        clusterName: {
          title: 'Clustername',
          type: 'string',
        },
        clusterRg: {
          title: 'Clusterrg',
          type: 'string',
        },
      },
      required: [
        'serviceConnection',
        'serviceConnectionId',
        'clusterName',
        'clusterRg',
      ],
      title: 'DeploymentTarget',
      type: 'object',
    },
    DeploymentTargetLevel: {
      description: 'Deployment target level.',
      enum: ['L4', 'L5', 'ALL'],
      title: 'DeploymentTargetLevel',
      type: 'string',
    },
    DevOpsOutput: {
      properties: {
        projectUrl: {
          title: 'Projecturl',
          type: 'string',
        },
        targetEnvironments: {
          additionalProperties: {
            $ref: '#/$defs/DeploymentTarget',
          },
          title: 'Targetenvironments',
          type: 'object',
        },
        variableGroups: {
          items: {
            type: 'string',
          },
          title: 'Variablegroups',
          type: 'array',
        },
      },
      required: ['projectUrl', 'targetEnvironments', 'variableGroups'],
      title: 'DevOpsOutput',
      type: 'object',
    },
    Domain: {
      enum: ['data', 'app', 'ai-gateway'],
      title: 'Domain',
      type: 'string',
    },
    DomainReport: {
      properties: {
        nodeId: {
          description: 'The ID of the node in the cluster.',
          title: 'Node ID',
          type: 'string',
        },
        cert: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Name of the certificate',
        },
        kvId: {
          anyOf: [
            {
              description: 'Canonical reference to resource.',
              title: 'Azure resource id.',
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Keyvault Resource ID',
        },
        role: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The role of the cell within the platform instance.',
          title: 'Role',
        },
        url: {
          description: 'The URL of the domain.',
          title: 'URL',
          type: 'string',
        },
        flavour: {
          $ref: '#/$defs/Domain',
          description: 'The flavour of the domain.',
          title: 'Flavour',
        },
      },
      required: ['nodeId', 'role', 'url', 'flavour'],
      title: 'DomainReport',
      type: 'object',
    },
    DspConfiguration: {
      properties: {
        LinkedProducts: {
          default: [],
          description:
            'Linked products associated with the DSP Product Manifest.',
          items: {
            $ref: '#/$defs/DspLinkedProduct',
          },
          title: 'Linked Products',
          type: 'array',
        },
      },
      title: 'DspConfiguration',
      type: 'object',
    },
    DspIdentities: {
      properties: {
        aadGroups: {
          $ref: '#/$defs/AadGroups',
          description:
            'Azure Active Directory Groups to which the user belongs',
          title: 'Azure Active Directory Groups',
        },
      },
      required: ['aadGroups'],
      title: 'DspIdentities',
      type: 'object',
    },
    DspIntegrationConfig: {
      properties: {
        name: {
          description: 'Name of the DSP to be linked',
          title: 'DSP name',
          type: 'string',
        },
      },
      required: ['name'],
      title: 'DspIntegrationConfig',
      type: 'object',
    },
    DspLinkedProduct: {
      properties: {
        Name: {
          description: 'The name of the linked product.',
          title: 'Linked Product Name',
          type: 'string',
        },
        ObjectID: {
          description: 'The object ID of the linked product',
          format: 'uuid',
          title: 'Object ID',
          type: 'string',
        },
      },
      required: ['Name', 'ObjectID'],
      title: 'DspLinkedProduct',
      type: 'object',
    },
    DspManifest: {
      description: 'DspManifest model.',
      properties: {
        apiVersion: {
          const: 'dsp/v1',
          default: 'dsp/v1',
          description: 'The version of the API for the DSP Product Manifest.',
          title: 'API Version',
          type: 'string',
        },
        kind: {
          const: 'DspManifest',
          default: 'DspManifest',
          description: 'The kind of the DSP Product Manifest.',
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/DspMetadata',
          description: 'Metadata associated with the DSP Product Manifest.',
          title: 'Metadata',
        },
        spec: {
          $ref: '#/$defs/DspSpec',
          description:
            'The specification manifest detailing the configuration and deployment details',
          title: 'Specification Manifest',
        },
        identities: {
          $ref: '#/$defs/DspIdentities',
          description:
            'Azure Active Directory Groups to which the user belongs',
          title: 'Azure Active Directory Groups',
        },
        configuration: {
          $ref: '#/$defs/DspConfiguration',
          default: {},
          description:
            'Configuration associated with the DSP Product Manifest.',
          title: 'Configuration',
        },
        modules: {
          $ref: '#/$defs/Modules',
          description: 'Modules associated with the DSP Product Manifest.',
        },
      },
      required: ['kind', 'metadata', 'spec', 'identities', 'modules'],
      title: 'DspManifest',
      type: 'object',
    },
    DspMetadata: {
      properties: {
        name: {
          description: 'The name of the metadata entry',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'Name',
          type: 'string',
        },
        namespace: {
          description: 'The namespace of the metadata entry.',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'Namespace',
          type: 'string',
        },
        title: {
          default: '',
          description: 'A display name of the entity.',
          title: 'Title',
          type: 'string',
        },
        description: {
          default: '',
          description: 'A human readable description of the entity.',
          title: 'Description',
          type: 'string',
        },
        annotations: {
          $ref: '#/$defs/Annotations',
          description: 'Additional annotations for the metadata entry.',
        },
        labels: {
          additionalProperties: {
            type: 'string',
          },
          default: {},
          description: 'Labels object regarding the resource.',
          title: 'Labels',
          type: 'object',
        },
      },
      required: ['name', 'namespace', 'annotations'],
      title: 'DspMetadata',
      type: 'object',
    },
    DspSpec: {
      properties: {
        lifecycle: {
          $ref: '#/$defs/dsp_deployment__models__manifests___spec_choices__Lifecycle',
          description:
            'The lifecycle stage of the deployment (e.g., production, development).',
        },
        blueprintVersion: {
          description:
            'The version of the blueprint associated with the metadata',
          title: 'Blueprint Version',
          type: 'string',
        },
        environment: {
          $ref: '#/$defs/Environments',
          description:
            'Environment of the deployment (e.g., PROD, QUAL, DEV, TEST).',
          title: 'Environment',
        },
        reposDeployment: {
          description:
            'Flag indicating whether repositories deployment is enabled.',
          title: 'Repositories Deployment',
          type: 'boolean',
        },
        name: {
          description: 'The name of the deployment.',
          title: 'Name',
          type: 'string',
        },
        owner: {
          description: 'The owner of the deployment.',
          title: 'Owner',
          type: 'string',
        },
        ownerUID: {
          description: 'The unique identifier of the owner.',
          title: 'Owner UID',
          type: 'string',
        },
        costCenter: {
          description: 'The cost center associated with the deployment.',
          maxLength: 20,
          minLength: 1,
          pattern: '^[a-zA-Z0-9\\.]*$',
          title: 'Cost Center',
          type: 'string',
        },
        companyCode: {
          description: 'The company code associated with the deployment.',
          maxLength: 20,
          minLength: 1,
          pattern: '^[a-zA-Z0-9]*$',
          title: 'Company Code',
          type: 'string',
        },
        companyName: {
          description:
            'The name of the company associated with the deployment.',
          maxLength: 60,
          minLength: 1,
          title: 'Company Name',
          type: 'string',
        },
        purpose: {
          description: 'The purpose of the deployment.',
          title: 'Purpose',
          type: 'string',
        },
        configItem: {
          description: 'The configuration item associated with the deployment.',
          title: 'Configuration Item',
          type: 'string',
        },
        serviceLevelAgreement: {
          $ref: '#/$defs/ServiceLevelAgreement',
          description: 'The service level agreement for the deployment.',
          title: 'Service Level Agreement',
        },
        type: {
          $ref: '#/$defs/DspType',
          description: 'The type of deployment (e.g., External, Internal).',
          title: 'Type',
        },
        serviceLevel: {
          $ref: '#/$defs/ServiceLevel',
          description: 'The service level for the deployment.',
          title: 'Service Level',
        },
        region: {
          $ref: '#/$defs/Region',
          description: 'The region where the deployment is located.',
        },
        description: {
          description: 'A description of the deployment.',
          title: 'Description',
          type: 'string',
        },
        subscriptionID: {
          $ref: '#/$defs/SubscriptionID',
          description: 'The subscription ID associated with the deployment.',
          title: 'Subscription ID',
        },
        executionPlanConfiguration: {
          $ref: '#/$defs/ExecutionPlanConfiguration',
          description: 'The configuration for the execution plan.',
          title: 'Execution Plan Configuration',
        },
      },
      required: [
        'lifecycle',
        'blueprintVersion',
        'environment',
        'reposDeployment',
        'name',
        'owner',
        'ownerUID',
        'costCenter',
        'companyCode',
        'companyName',
        'purpose',
        'configItem',
        'serviceLevelAgreement',
        'type',
        'serviceLevel',
        'region',
        'description',
        'subscriptionID',
        'executionPlanConfiguration',
      ],
      title: 'DspSpec',
      type: 'object',
    },
    DspType: {
      description: 'Type of deployment',
      enum: ['Regular', 'External', 'Internal'],
      title: 'DspType',
      type: 'string',
    },
    EgressListenerConfig: {
      properties: {
        name: {
          description: 'Name of the listener.',
          title: 'Name',
          type: 'string',
        },
        route: {
          $ref: '#/$defs/HttpRoute',
          description: 'HTTP route configuration.',
          title: 'Route',
        },
        port: {
          description: 'Port for the listener.',
          title: 'Port',
          type: 'integer',
        },
        credential: {
          $ref: '#/$defs/UpstreamCredential',
          description: 'Credential for the upstream.',
          title: 'Credential',
        },
      },
      required: ['name', 'route', 'port', 'credential'],
      title: 'EgressListenerConfig',
      type: 'object',
    },
    EntityName: {
      maxLength: 253,
      minLength: 3,
      pattern: '^[a-z]+(?:-?[a-z0-9]+)+$',
      type: 'string',
    },
    Environments: {
      description: 'Lifecycle of the model',
      enum: ['PROD', 'QUAL', 'DEV', 'TEST'],
      title: 'Environments',
      type: 'string',
    },
    EnvoyConfigBase: {
      properties: {},
      title: 'EnvoyConfigBase',
      type: 'object',
    },
    EventGrid: {
      properties: {
        kind: {
          const: 'eventgrid',
          default: 'eventgrid',
          description: 'EventGrid Resource Kind',
          title: 'EventGrid Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'EventGrid Resource Name',
          title: 'EventGrid Resource Name',
          type: 'string',
        },
      },
      required: ['Name'],
      title: 'EventGrid',
      type: 'object',
    },
    ExecutionPlanConfiguration: {
      properties: {
        Infra: {
          $ref: '#/$defs/InfraConfigurationPlan',
          description: 'Configuration plan for infrastructure deployments.',
          title: 'Infrastructure Configuration Plan',
        },
      },
      required: ['Infra'],
      title: 'ExecutionPlanConfiguration',
      type: 'object',
    },
    ExposedServiceConfig: {
      properties: {
        name: {
          description: 'Name af the K8s service inside the product namespace.',
          examples: ['k8s-service-name'],
          maxLength: 62,
          minLength: 1,
          pattern:
            'prodai_ada|ai_store|digital_twin|prodai_nasa|competitor_intelligence|app_dev_enablement|scott_solutions|spinal_os|dob_aisol|advanced_imaging|forecasting_platform|data_and_ai_academy|ai_solutions|gb_gtu|^[a-z]+(?:-?[a-z0-9]+)+$',
          title: 'Service name.',
          type: 'string',
        },
        port: {
          $ref: '#/$defs/PortNumber',
          default: 80,
          description: 'Target port of the service.',
          title: 'Service port.',
        },
        domains: {
          default: ['app'],
          description: 'The domains this service is available at.',
          items: {
            $ref: '#/$defs/Domain',
          },
          title: 'Routing domains',
          type: 'array',
        },
        routes: {
          description: 'Routes exposed on the service.',
          items: {
            $ref: '#/$defs/RoutingRule',
          },
          title: 'Service routes',
          type: 'array',
        },
      },
      required: ['name', 'routes'],
      title: 'ExposedServiceConfig',
      type: 'object',
    },
    ExposedServiceEntry: {
      properties: {
        name: {
          description: 'Name of the service in Kubernetes.',
          title: 'Kubernetes service name',
          type: 'string',
        },
        urls: {
          description: 'URLs for the service.',
          items: {
            $ref: '#/$defs/ExposedServiceUrl',
          },
          title: 'URLs for the service.',
          type: 'array',
        },
      },
      required: ['name'],
      title: 'ExposedServiceEntry',
      type: 'object',
    },
    ExposedServiceUrl: {
      properties: {
        url: {
          description: 'URL for the service.',
          title: 'URL',
          type: 'string',
        },
        allowedGroups: {
          default: [],
          description: 'Groups that have access to this URL',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
        domain: {
          $ref: '#/$defs/Domain',
          default: ['app'],
          description: 'The domain this URL is available at.',
          title: 'URL domain',
        },
      },
      required: ['url'],
      title: 'ExposedServiceUrl',
      type: 'object',
    },
    ExtensionConfig: {
      properties: {
        appstore: {
          default: [],
          description: 'AppStore deployment configuration',
          items: {
            $ref: '#/$defs/AppStoreDeployment',
          },
          title: 'Integrated AppStore deployments',
          type: 'array',
        },
        dsp: {
          anyOf: [
            {
              $ref: '#/$defs/DspIntegrationConfig',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'DSP integration configuration',
          title: 'DSP Integration',
        },
        aigateway: {
          anyOf: [
            {
              $ref: '#/$defs/AIGatewayConfig',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'AI Gateway extension configuration',
          title: 'AI Gateway extension',
        },
      },
      title: 'ExtensionConfig',
      type: 'object',
    },
    FeatureFlag: {
      enum: [
        'github-env-next',
        'managed-report-types',
        'structured-logging',
        'appstore-deployment',
      ],
      title: 'FeatureFlag',
      type: 'string',
    },
    GatewayEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        output: {
          $ref: '#/$defs/GatewayReport',
        },
      },
      required: ['stackVersion', 'output'],
      title: 'GatewayEntry',
      type: 'object',
    },
    GatewayReport: {
      properties: {
        kind: {
          description: 'The kind of the gateway.',
          title: 'Kind',
          type: 'string',
        },
        domains: {
          description: 'List of domains exposed by the gateway.',
          items: {
            $ref: '#/$defs/DomainReport',
          },
          title: 'Domains',
          type: 'array',
        },
        customDomains: {
          description: 'List of additional custom domains',
          items: {
            $ref: '#/$defs/CustomDomainReport',
          },
          title: 'Customdomains',
          type: 'array',
        },
      },
      required: ['kind', 'domains'],
      title: 'GatewayReport',
      type: 'object',
    },
    GitHubEnterpriseEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        output: {
          $ref: '#/$defs/GitHubEnterpriseReport',
        },
        input: {
          $ref: '#/$defs/GitHubEnterpriseManifest',
        },
        policies: {
          $ref: '#/$defs/PolicyReport',
          title: 'Policies.',
        },
      },
      required: ['stackVersion', 'output', 'input', 'policies'],
      title: 'GitHubEnterpriseEntry',
      type: 'object',
    },
    GitHubEnterpriseManifest: {
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          default: 'core.argus.ai/v1alpha1',
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'GitHubEnterpriseManifest',
          default: 'GitHubEnterpriseManifest',
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/Metadata',
        },
        spec: {
          $ref: '#/$defs/GitHubEnterpriseSpec',
        },
      },
      required: ['spec', 'metadata', 'apiVersion', 'kind'],
      title: 'GitHubEnterpriseManifest',
      type: 'object',
    },
    GitHubEnterpriseReport: {
      properties: {
        registered: {
          $ref: '#/$defs/GitHubMembership',
        },
        id_name_map: {
          description: 'A list of tuples containing the group id and name',
          items: {
            maxItems: 2,
            minItems: 2,
            prefixItems: [
              {
                type: 'string',
              },
              {
                type: 'string',
              },
            ],
            type: 'array',
          },
          title: 'Id Name Map',
          type: 'array',
        },
      },
      required: ['registered', 'id_name_map'],
      title: 'GitHubEnterpriseReport',
      type: 'object',
    },
    GitHubEnterpriseSpec: {
      properties: {
        teams: {
          description: 'The teams in the organization.',
          items: {
            $ref: '#/$defs/GitHubTeamConfig',
          },
          title: 'Teams',
          type: 'array',
        },
      },
      required: ['teams'],
      title: 'GitHubEnterpriseSpec',
      type: 'object',
    },
    GitHubMembership: {
      properties: {
        users: {
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Users',
          type: 'array',
        },
        groups: {
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Groups',
          type: 'array',
        },
      },
      required: ['users', 'groups'],
      title: 'GitHubMembership',
      type: 'object',
    },
    GitHubTeamConfig: {
      properties: {
        name: {
          description: 'The name of the team.',
          title: 'Team name',
          type: 'string',
        },
        description: {
          description: 'The description of the team.',
          title: 'Description',
          type: 'string',
        },
        objectId: {
          description:
            'The object ID of the group that represents the team in AAD.',
          format: 'uuid',
          title: 'Objectid',
          type: 'string',
        },
        role: {
          $ref: '#/$defs/TeamRole',
          default: 'developers',
          description: 'The role of the team.',
        },
      },
      required: ['name', 'description', 'objectId'],
      title: 'GitHubTeamConfig',
      type: 'object',
    },
    GitHubTeamInfo: {
      properties: {
        ghId: {
          description: 'The GitHub ID of the team.',
          title: 'Ghid',
          type: 'string',
        },
        slug: {
          title: 'Slug',
          type: 'string',
        },
        mappedObjectId: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'The object ID of the group that represents the team in AAD.',
          title: 'Mappedobjectid',
        },
        ghEmuId: {
          anyOf: [
            {
              type: 'integer',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'The GitHub Enterprise Managed Users ID of the team.',
          title: 'Ghemuid',
        },
        role: {
          $ref: '#/$defs/TeamRole',
          default: 'developers',
          description: 'The role of the team.',
        },
      },
      required: ['ghId', 'slug'],
      title: 'GitHubTeamInfo',
      type: 'object',
    },
    Group: {
      properties: {
        Name: {
          description: 'The name of the group.',
          title: 'Group Name',
          type: 'string',
        },
        Description: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: "A brief description of the group's purpose or role",
          title: 'Group Description',
        },
        Owner: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'The owner or administrator of the group.',
          title: 'Group Owner',
        },
        UCSync: {
          anyOf: [
            {
              type: 'boolean',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Indicates whether the group needs to be synchronized with Unity Catalog.',
          title: 'Unity Catalog Sync',
        },
      },
      required: ['Name'],
      title: 'Group',
      type: 'object',
    },
    GroupInfo: {
      properties: {
        objectId: {
          title: 'Objectid',
          type: 'string',
        },
        displayName: {
          title: 'Displayname',
          type: 'string',
        },
      },
      required: ['objectId', 'displayName'],
      title: 'GroupInfo',
      type: 'object',
    },
    HTTPRouteTimeouts: {
      properties: {
        request: {
          description: 'Requests timeout in seconds.',
          title: 'Requests',
          type: 'integer',
        },
        backend_request: {
          description: 'Backend request timeout in seconds.',
          title: 'Backend Request',
          type: 'integer',
        },
      },
      required: ['request', 'backend_request'],
      title: 'HTTPRouteTimeouts',
      type: 'object',
    },
    HttpHeaderMatch: {
      properties: {
        type: {
          $ref: '#/$defs/HttpHeaderMatchType',
          description:
            'Type specifies how to match against the value of the header.',
          title: 'Type',
        },
        name: {
          description:
            'Name is the name of the HTTP Header to be matched. Name matching MUST be case insensitive.',
          title: 'Name',
          type: 'string',
        },
        value: {
          description: 'VValue is the value of HTTP Header to be matched.',
          title: 'Value',
          type: 'string',
        },
      },
      required: ['type', 'name', 'value'],
      title: 'HttpHeaderMatch',
      type: 'object',
    },
    HttpHeaderMatchType: {
      enum: ['exact'],
      title: 'HttpHeaderMatchType',
      type: 'string',
    },
    HttpPathMatch: {
      properties: {
        type: {
          $ref: '#/$defs/HttpPathMatchType',
          description: 'Type specifies how to match against the path Value.',
          title: 'Type',
        },
        value: {
          description: 'Value of the HTTP path to match against.',
          title: 'Value',
          type: 'string',
        },
      },
      required: ['type', 'value'],
      title: 'HttpPathMatch',
      type: 'object',
    },
    HttpPathMatchType: {
      description:
        'PathMatchType specifies the semantics of how HTTP paths should be compared',
      enum: ['exact', 'path-prefix'],
      title: 'HttpPathMatchType',
      type: 'string',
    },
    HttpRoute: {
      properties: {
        hostnames: {
          description: 'List of hostnames.',
          items: {
            type: 'string',
          },
          title: 'Hostnames',
          type: 'array',
        },
        rules: {
          description: 'List of rules.',
          items: {
            $ref: '#/$defs/HttpRouteRule',
          },
          title: 'Rules',
          type: 'array',
        },
      },
      required: ['hostnames', 'rules'],
      title: 'HttpRoute',
      type: 'object',
    },
    HttpRouteMatch: {
      description:
        'HTTPRouteMatch defines the predicate used to match requests to a given action.\n\nMultiple match types are ANDed together, i.e. the match will evaluate to true only\nif all conditions are satisfied.',
      properties: {
        path: {
          $ref: '#/$defs/HttpPathMatch',
          default: {
            type: 'path-prefix',
            value: '/',
          },
          title: 'Path',
        },
        headers: {
          anyOf: [
            {
              items: {
                $ref: '#/$defs/HttpHeaderMatch',
              },
              type: 'array',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Headers',
        },
        typed_per_filter_config: {
          anyOf: [
            {
              additionalProperties: {
                $ref: '#/$defs/EnvoyConfigBase',
              },
              type: 'object',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Typed Per Filter Config',
        },
      },
      title: 'HttpRouteMatch',
      type: 'object',
    },
    HttpRouteRule: {
      description:
        'A routing rule to route HTTP requests to a backend.\n\nHTTPRouteRule defines semantics for matching an HTTP request based on conditions (matches),\nprocessing it (filters), and forwarding the request to an API object (backend_refs).\n\nBased on The k8s Gateway APIs [HTTPRouteRule].\n\n[HTTPRouteRule]: https://gateway-api.sigs.k8s.io/reference/spec/#gateway.networking.k8s.io/v1.HTTPRouteRule',
      properties: {
        name: {
          description: 'Name of the rule.',
          title: 'Rule name',
          type: 'string',
        },
        matches: {
          items: {
            $ref: '#/$defs/HttpRouteMatch',
          },
          title: 'Matches',
          type: 'array',
        },
        backend_refs: {
          description: 'List of backends.',
          items: {
            anyOf: [
              {
                $ref: '#/$defs/BackendRef',
              },
              {
                $ref: '#/$defs/UpstreamRef',
              },
            ],
          },
          title: 'Backends',
          type: 'array',
        },
        timeouts: {
          anyOf: [
            {
              $ref: '#/$defs/HTTPRouteTimeouts',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Timeout in seconds.',
          title: 'Timeout',
        },
        auto_host_rewrite: {
          anyOf: [
            {
              type: 'boolean',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'If True, the host header will be swapped with the hostname of the upstream host.',
          title: 'Auto Host Rewrite',
        },
      },
      required: ['name', 'backend_refs'],
      title: 'HttpRouteRule',
      type: 'object',
    },
    IdentityInfo: {
      properties: {
        kind: {
          const: 'identity',
          default: 'identity',
          title: 'Kind',
          type: 'string',
        },
        clientId: {
          title: 'Identity client ID.',
          type: 'string',
        },
        objectId: {
          title: 'Identity object ID.',
          type: 'string',
        },
        displayName: {
          title: 'Identity display name.',
          type: 'string',
        },
      },
      required: ['kind', 'clientId', 'objectId', 'displayName'],
      title: 'IdentityInfo',
      type: 'object',
    },
    InfraCellRef: {
      description:
        'A reference to a infrstructure cell associated with a logical stage.',
      properties: {
        name: {
          description: 'The globally unique name of the cell.',
          title: 'name',
          type: 'string',
        },
        stage: {
          $ref: '#/$defs/LogicalStage',
          description:
            'The logical stage describes the order of a cell within a deployment sequence.',
          title: 'logical stage',
        },
      },
      required: ['name', 'stage'],
      title: 'InfraCellRef',
      type: 'object',
    },
    InfraConfigurationPlan: {
      properties: {
        DatabricksDeployment: {
          default: false,
          description:
            'Flag indicating whether Databricks deployment is enabled.',
          title: 'Databricks Deployment',
          type: 'boolean',
        },
        DatabricksINDeployment: {
          default: false,
          description:
            'Flag indicating whether Databricks IN deployment is enabled.',
          title: 'Databricks IN Deployment',
          type: 'boolean',
        },
        ADFDeployment: {
          default: false,
          description:
            'Flag indicating whether Azure Data Factory deployment is enabled.',
          title: 'ADF Deployment',
          type: 'boolean',
        },
        SQLServerDeployment: {
          default: false,
          description:
            'Flag indicating whether SQL Server deployment is enabled.',
          title: 'SQL Server Deployment',
          type: 'boolean',
        },
        SynapseDeployment: {
          default: false,
          description: 'Flag indicating whether Synapse deployment is enabled.',
          title: 'Synapse Deployment',
          type: 'boolean',
        },
        ADLSDeployment: {
          default: false,
          description:
            'Flag indicating whether Azure Data Lake Storage deployment is enabled.',
          title: 'ADLS Deployment',
          type: 'boolean',
        },
        AMLDeployment: {
          default: false,
          description:
            'Flag indicating whether Azure Machine Learning deployment is enabled.',
          title: 'AML Deployment',
          type: 'boolean',
        },
        OpenAIDeployment: {
          default: false,
          description: 'Flag indicating whether OpenAI deployment is enabled.',
          title: 'OpenAI Deployment',
          type: 'boolean',
        },
        EventgridDeployment: {
          default: false,
          description:
            'Flag indicating whether Event Grid deployment is enabled.',
          title: 'Event Grid Deployment',
          type: 'boolean',
        },
        CognitiveSearchDeployment: {
          default: false,
          description:
            'Flag indicating whether Cognitive Search deployment is enabled.',
          title: 'Cognitive Search Deployment',
          type: 'boolean',
        },
        LogAnalyticsDeployment: {
          default: false,
          description:
            'Flag indicating whether Log Analytics deployment is enabled.',
          title: 'Log Analytics Deployment',
          type: 'boolean',
        },
        AiBoxDeployment: {
          default: false,
          description:
            "Indicates whether the AI Box is deployed. Set to 'True' if AI Box deployment is required.",
          title: 'AI Box Deployment',
          type: 'boolean',
        },
        AIServicesDeployment: {
          default: false,
          description:
            "Indicates whether AI Services are deployed. Set to 'True' if AI Services deployment is required.",
          title: 'AI Services Deployment',
          type: 'boolean',
        },
      },
      title: 'InfraConfigurationPlan',
      type: 'object',
    },
    InfrastructureCellEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        output: {
          $ref: '#/$defs/InfrastructureCellReport',
        },
      },
      required: ['stackVersion', 'output'],
      title: 'InfrastructureCellEntry',
      type: 'object',
    },
    InfrastructureCellReport: {
      properties: {
        cellId: {
          description: 'The ID of the node in the instance.',
          title: 'Cell ID',
          type: 'string',
        },
        kind: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The role of the cell within the platform instance.',
          title: 'Role',
        },
        logicalStage: {
          $ref: '#/$defs/LogicalStage',
          description: 'The logical stage of the cell.',
          title: 'Logical stage',
        },
        cluster: {
          $ref: '#/$defs/K8sClusterResource',
          description: 'The cluster information.',
        },
        network: {
          $ref: '#/$defs/NetworkReport',
          description: 'The network information.',
        },
      },
      required: ['cellId', 'kind', 'logicalStage', 'cluster', 'network'],
      title: 'InfrastructureCellReport',
      type: 'object',
    },
    InstanceCellEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        manifest: {
          $ref: '#/$defs/ProductCellManifest',
        },
        report: {
          $ref: '#/$defs/InstanceCellReport',
        },
        policies: {
          $ref: '#/$defs/PolicyReport',
          title: 'Policies.',
        },
        resources: {
          $ref: '#/$defs/ResourcesReport',
          title: 'Resources.',
        },
      },
      required: ['stackVersion', 'manifest', 'report', 'policies', 'resources'],
      title: 'InstanceCellEntry',
      type: 'object',
    },
    InstanceCellKind: {
      enum: ['platform', 'product'],
      title: 'InstanceCellKind',
      type: 'string',
    },
    InstanceCellRef: {
      properties: {
        kind: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The kind of the cell.',
          title: 'Cell kind',
        },
        cellId: {
          description: 'The unique identifier of the cell.',
          title: 'Cell ID',
          type: 'string',
        },
      },
      required: ['kind', 'cellId'],
      title: 'InstanceCellRef',
      type: 'object',
    },
    InstanceCellReport: {
      properties: {
        cellId: {
          description: 'The ID of the node in the instance.',
          title: 'Cell ID',
          type: 'string',
        },
        kind: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The role of the cell within the platform instance.',
          title: 'Role',
        },
        logicalStage: {
          $ref: '#/$defs/LogicalStage',
          description: 'The logical stage of the cell.',
          title: 'Logical stage',
        },
        subscriptions: {
          description: 'Subscriptions the cell is deployed to.',
          items: {
            $ref: '#/$defs/InstanceCellSubscription',
          },
          title: 'Subscriptions',
          type: 'array',
        },
        clusters: {
          description: 'Clusters deployed to instance cell.',
          items: {
            $ref: '#/$defs/CellClusterReport',
          },
          title: 'Cluster',
          type: 'array',
        },
      },
      required: ['cellId', 'kind', 'logicalStage', 'subscriptions', 'clusters'],
      title: 'InstanceCellReport',
      type: 'object',
    },
    InstanceCellSubscription: {
      properties: {
        id: {
          title: 'Id',
          type: 'string',
        },
        name: {
          title: 'Name',
          type: 'string',
        },
        kind: {
          $ref: '#/$defs/InstanceCellSubscriptionKind',
          description: 'The kind of the subscription.',
          title: 'Subscription kind',
        },
      },
      required: ['id', 'name', 'kind'],
      title: 'InstanceCellSubscription',
      type: 'object',
    },
    InstanceCellSubscriptionKind: {
      enum: ['dsp', 'appstore', 'argus'],
      title: 'InstanceCellSubscriptionKind',
      type: 'string',
    },
    InstanceConfig: {
      properties: {
        config: {
          anyOf: [
            {
              $ref: '#/$defs/InstanceSpec',
            },
            {
              $ref: '#/$defs/StagedConfigDict_InstanceSpec_',
            },
          ],
          description: 'Configuration for the resource',
          title: 'Resource configuration',
        },
        stages: {
          description: 'Globally unique names for the instances.',
          items: {
            $ref: '#/$defs/InfraCellRef',
          },
          title: 'Instance names',
          type: 'array',
        },
      },
      required: ['config'],
      title: 'InstanceConfig',
      type: 'object',
    },
    InstanceEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        manifest: {
          $ref: '#/$defs/PlatformManifest',
        },
        report: {
          $ref: '#/$defs/InstanceReport',
        },
        policies: {
          $ref: '#/$defs/PolicyReport',
          title: 'Policies.',
        },
        resources: {
          $ref: '#/$defs/ResourcesReport',
          title: 'Resources.',
        },
      },
      required: ['stackVersion', 'manifest', 'report', 'policies', 'resources'],
      title: 'InstanceEntry',
      type: 'object',
    },
    InstanceGroups: {
      description:
        'Entry ID groups that are used across cells within a platform instance.',
      properties: {
        workspaces: {
          $ref: '#/$defs/GroupInfo',
        },
        workspacePrincipals: {
          $ref: '#/$defs/GroupInfo',
          title: 'Workspace principals',
        },
        products: {
          $ref: '#/$defs/GroupInfo',
        },
        productPrincipals: {
          $ref: '#/$defs/GroupInfo',
          title: 'Product principals',
        },
        kubelets: {
          $ref: '#/$defs/GroupInfo',
        },
      },
      required: [
        'workspaces',
        'workspacePrincipals',
        'products',
        'productPrincipals',
        'kubelets',
      ],
      title: 'InstanceGroups',
      type: 'object',
    },
    InstanceLayout: {
      properties: {
        platform: {
          $ref: '#/$defs/InfraCellRef',
        },
        products: {
          items: {
            $ref: '#/$defs/InfraCellRef',
          },
          title: 'Products',
          type: 'array',
        },
      },
      required: ['platform', 'products'],
      title: 'InstanceLayout',
      type: 'object',
    },
    InstanceRegistryReport: {
      properties: {
        repository: {
          description: 'The name of the git repository.',
          title: 'Repository',
          type: 'string',
        },
        catalogRepository: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'The name of the git repository for the catalog.',
          title: 'Catalog repository',
        },
        agentsGroupObjectId: {
          description: 'The object ID of the service principal group.',
          title: 'Agents group object ID',
          type: 'string',
        },
        agents: {
          description:
            'The service principals acting as agents to deploy into instance cells.',
          items: {
            $ref: '#/$defs/RegistryAgentReport',
          },
          title: 'Agents',
          type: 'array',
        },
      },
      required: ['repository', 'agentsGroupObjectId', 'agents'],
      title: 'InstanceRegistryReport',
      type: 'object',
    },
    InstanceReport: {
      properties: {
        name: {
          title: 'Name',
          type: 'string',
        },
        logicalStage: {
          $ref: '#/$defs/LogicalStage',
          title: 'Logical stage',
        },
        layout: {
          $ref: '#/$defs/DeployedInstanceLayout',
          title: 'Instance layout',
        },
        platformCells: {
          description: 'The cells deployed in the platform instance.',
          items: {
            $ref: '#/$defs/InstanceCellReport',
          },
          title: 'Platform cells',
          type: 'array',
        },
        registry: {
          $ref: '#/$defs/InstanceRegistryReport',
        },
        groups: {
          $ref: '#/$defs/InstanceGroups',
        },
        resources: {
          $ref: '#/$defs/InstanceResources',
        },
      },
      required: [
        'name',
        'logicalStage',
        'layout',
        'registry',
        'groups',
        'resources',
      ],
      title: 'InstanceReport',
      type: 'object',
    },
    InstanceResources: {
      properties: {
        ociRegistry: {
          $ref: '#/$defs/AcrResource',
          title: 'Container registry',
        },
        observability: {
          $ref: '#/$defs/ObservabilityResources',
          title: 'Observability resources',
        },
        portal: {
          anyOf: [
            {
              $ref: '#/$defs/PortalResources',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Portal resources',
        },
        legacy: {
          $ref: '#/$defs/LegacyResources',
          title: 'Legacy resources',
        },
      },
      required: ['ociRegistry', 'legacy'],
      title: 'InstanceResources',
      type: 'object',
    },
    InstanceSpec: {
      properties: {
        location: {
          $ref: '#/$defs/AzureRegion',
          default: 'westeurope',
          description: 'Azure region where resources should be provisioned.',
          title: 'Location',
        },
        productCells: {
          description: 'List of product cells.',
          items: {
            $ref: '#/$defs/InfraCellRef',
          },
          title: 'Product cells',
          type: 'array',
        },
      },
      title: 'InstanceSpec',
      type: 'object',
    },
    JupyterHubConfig: {
      description:
        'Web-based interactive development environment for notebooks, code, and data.',
      properties: {
        kind: {
          const: 'jupyter',
          default: 'jupyter',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        imageTag: {
          default: 'latest',
          description: 'Jupyter hub docker image tag to be used',
          title: 'Image tag',
          type: 'string',
        },
        environments: {
          default: [
            {
              image: 'basfargusglobal.azurecr.io/argus-jupyterhub-datascience',
              tag: 'latest',
              displayName: 'Default Data Science Notebook',
              description:
                'Default notebook containing various popular data science libraries.',
            },
          ],
          description:
            'List of user environments users can choose to run their notebook in.',
          items: {
            $ref: '#/$defs/UserEnvironment',
          },
          title: 'User environments',
          type: 'array',
        },
      },
      required: ['kind'],
      title: 'JupyterHubConfig',
      type: 'object',
    },
    K8sClusterResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        location: {
          description: 'Azure Region where the cluster is provisioned.',
          title: 'Location',
          type: 'string',
        },
        kubernetesVersion: {
          description: 'Kubernetes version installed in the cluster.',
          title: 'Kubernetesversion',
          type: 'string',
        },
        kubernetesOrchestratorVersion: {
          description: 'Kubernetes orchestrator version.',
          title: 'Kubernetesorchestratorversion',
          type: 'string',
        },
        fqdn: {
          description:
            'The fully qualified domain name of the Kubernetes cluster.',
          title: 'Fqdn',
          type: 'string',
        },
        oidcIssuerUrl: {
          description: 'The OpenID Connect issuer URL for the cluster.',
          title: 'Oidcissuerurl',
          type: 'string',
        },
        kubeletObjectId: {
          description:
            'The object ID of the kubelet identity associated with the cluster.',
          title: 'Kubeletobjectid',
          type: 'string',
        },
      },
      required: [
        'resourceId',
        'location',
        'kubernetesVersion',
        'kubernetesOrchestratorVersion',
        'fqdn',
        'oidcIssuerUrl',
        'kubeletObjectId',
      ],
      title: 'K8sClusterResource',
      type: 'object',
    },
    K8sCpuType: {
      pattern: '^(([0-9]+[.])?[0-9]+)(m)?$',
      type: 'string',
    },
    K8sMemoryType: {
      pattern: '^([0-9]+)(Ki|Ei|Pi|Ti|Gi|Mi|[EPTGMKmk])?$',
      type: 'string',
    },
    K8sReleaseInfo: {
      properties: {
        namespace: {
          title: 'Namespace',
          type: 'string',
        },
      },
      required: ['namespace'],
      title: 'K8sReleaseInfo',
      type: 'object',
    },
    K8sResourceConfig: {
      properties: {
        requests: {
          $ref: '#/$defs/K8sResources',
          description: 'Default resource requests.',
          title: 'Default resource requests',
        },
        limits: {
          $ref: '#/$defs/K8sResources',
          description: 'Default resource limits.',
          title: 'Default resource limits',
        },
      },
      required: ['requests', 'limits'],
      title: 'K8sResourceConfig',
      type: 'object',
    },
    K8sResources: {
      properties: {
        cpu: {
          $ref: '#/$defs/K8sCpuType',
          description: 'Specifies the number of physical or virtual cores.',
          title: 'CPU resource units',
        },
        memory: {
          $ref: '#/$defs/K8sMemoryType',
          description: 'Specifies the number of bytes of physical memory.',
          title: 'Memory resource units',
        },
      },
      required: ['cpu', 'memory'],
      title: 'K8sResources',
      type: 'object',
    },
    KeyVaultResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'key-vault',
          default: 'key-vault',
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the key vault is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        uri: {
          description: 'The URI of the key vault.',
          title: 'URI',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'uri', 'resourceId'],
      title: 'KeyVaultResource',
      type: 'object',
    },
    KustoBackendAppReport: {
      properties: {
        appObjectId: {
          title: 'Application object ID',
          type: 'string',
        },
        appClientId: {
          title: 'Application client ID',
          type: 'string',
        },
        spObjectId: {
          title: 'Service principal object ID',
          type: 'string',
        },
        spClientId: {
          title: 'Service principal client ID',
          type: 'string',
        },
      },
      required: ['appObjectId', 'appClientId', 'spObjectId', 'spClientId'],
      title: 'KustoBackendAppReport',
      type: 'object',
    },
    KustoBackendReport: {
      properties: {
        cluster: {
          $ref: '#/$defs/KustoClusterResource',
          title: 'ADX cluster',
        },
        database: {
          $ref: '#/$defs/BaseResourceInfo',
          title: 'ADX database',
        },
        ingestor: {
          $ref: '#/$defs/KustoBackendAppReport',
          description:
            'The object ID of the service principal used to ingest data into the cluster.',
          title: 'Ingestor object ID',
        },
      },
      required: ['cluster', 'database', 'ingestor'],
      title: 'KustoBackendReport',
      type: 'object',
    },
    KustoClusterResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'kusto-cluster',
          default: 'kusto-cluster',
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'The location where the Kusto Cluster is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        uri: {
          description: 'The FQDN of the Azure Kusto Cluster.',
          title: 'Cluster URI',
          type: 'string',
        },
        dataIngestionUri: {
          description: 'The Kusto Cluster URI to be used for data ingestion.',
          title: 'Data ingestion uri',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'uri', 'dataIngestionUri', 'resourceId'],
      title: 'KustoClusterResource',
      type: 'object',
    },
    LegacyResources: {
      properties: {
        shared_groups: {
          $ref: '#/$defs/SharedGroupsReport',
        },
      },
      required: ['shared_groups'],
      title: 'LegacyResources',
      type: 'object',
    },
    LinkedGitHubTeamConfig: {
      properties: {
        slug: {
          description: 'The slug of the GitHub team.',
          title: 'Team name',
          type: 'string',
        },
        role: {
          $ref: '#/$defs/TeamRole',
          default: 'developers',
          description: 'The role of the team.',
        },
      },
      required: ['slug'],
      title: 'LinkedGitHubTeamConfig',
      type: 'object',
    },
    LogAnalytics: {
      properties: {
        kind: {
          const: 'log_analytics',
          default: 'log_analytics',
          description: 'LogAnalytics Resource Kind',
          title: 'LogAnalytics Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'LogAnalytics Resource Name',
          title: 'LogAnalytics Resource Name',
          type: 'string',
        },
        RetentionPolicyDays: {
          default: 30,
          description: 'Retention Policy Days',
          maximum: 365,
          minimum: 1,
          title: 'Retention Policy Days',
          type: 'integer',
        },
      },
      required: ['Name'],
      title: 'LogAnalytics',
      type: 'object',
    },
    LogicalStage: {
      enum: ['development', 'staging', 'production'],
      title: 'LogicalStage',
      type: 'string',
    },
    ManifestMetadata: {
      properties: {
        name: {
          description:
            'Name of the entity. May only contain lowercase letters and hyphens',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        namespace: {
          description: 'Namespace the entity is defined in.',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        title: {
          default: '',
          description: 'A display name of the entity.',
          title: 'Title',
          type: 'string',
        },
        description: {
          default: '',
          description: 'A human readable description of the entity.',
          title: 'Description',
          type: 'string',
        },
        annotations: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Annotations object regarding the resource.',
          title: 'Annotations',
          type: 'object',
        },
        labels: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Labels object regarding the resource.',
          title: 'Labels',
          type: 'object',
        },
        role: {
          $ref: '#/$defs/ManifestRole',
          default: 'product',
          description: 'Role of the entity.',
          title: 'Role',
        },
      },
      required: ['name', 'namespace'],
      title: 'ManifestMetadata',
      type: 'object',
    },
    ManifestRole: {
      enum: ['platform', 'product'],
      title: 'ManifestRole',
      type: 'string',
    },
    MemgraphConfig: {
      description: 'A high-performance, in-memory graph database',
      properties: {
        kind: {
          const: 'memgraph',
          default: 'memgraph',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chartVersion: {
          default: '2.1.3',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        image: {
          description: 'Memgraph docker image to be used',
          discriminator: {
            mapping: {
              'memgraph/memgraph': '#/$defs/MemgraphImage',
              'memgraph/memgraph-mage': '#/$defs/MemgraphMageImage',
            },
            propertyName: 'name',
          },
          oneOf: [
            {
              $ref: '#/$defs/MemgraphImage',
            },
            {
              $ref: '#/$defs/MemgraphMageImage',
            },
          ],
          title: 'Image',
        },
        deployments: {
          default: [],
          description: 'Deployments of unique Memgraph instances.',
          items: {
            $ref: '#/$defs/MemgraphDeployment',
          },
          title: 'Memgraph deployments',
          type: 'array',
          'vsc:action': 'array',
        },
      },
      required: ['kind'],
      title: 'MemgraphConfig',
      type: 'object',
    },
    MemgraphDeployment: {
      properties: {
        name: {
          default: 'memgraph',
          title: 'Deployment name',
          type: 'string',
        },
        resourcesRequests: {
          $ref: '#/$defs/K8sResources',
          default: {
            cpu: '1000m',
            memory: '5000',
          },
          description: 'Request Kubernetes resources for Memgraph instance',
        },
        resourcesLimits: {
          $ref: '#/$defs/K8sResources',
          default: {
            cpu: '2000m',
            memory: '10000',
          },
          description: 'Limits on Kubernetes resources for Memgraph instance',
        },
        extraArgs: {
          description: 'Extra arguments to pass to Memgraph',
          items: {
            type: 'string',
          },
          title: 'Extraargs',
          type: 'array',
        },
      },
      title: 'MemgraphDeployment',
      type: 'object',
    },
    MemgraphImage: {
      properties: {
        name: {
          const: 'memgraph/memgraph',
          default: 'memgraph/memgraph',
          description: 'Image name',
          title: 'Name',
          type: 'string',
        },
        tag: {
          $ref: '#/$defs/MemgraphImageTag',
          default: '2.20.1',
          title: 'Image tag',
        },
      },
      title: 'MemgraphImage',
      type: 'object',
    },
    MemgraphImageTag: {
      enum: [
        '2.16.1',
        '2.17.0',
        '2.18.0',
        '2.18.1',
        '2.19.0',
        '2.20.0',
        '2.20.1',
      ],
      title: 'MemgraphImageTag',
      type: 'string',
    },
    MemgraphMageImage: {
      properties: {
        name: {
          const: 'memgraph/memgraph-mage',
          default: 'memgraph/memgraph-mage',
          description: 'Image name',
          title: 'Name',
          type: 'string',
        },
        tag: {
          $ref: '#/$defs/MemgraphMageImageTag',
          default: '3.0-memgraph-3.0',
          title: 'Image tag',
        },
      },
      title: 'MemgraphMageImage',
      type: 'object',
    },
    MemgraphMageImageTag: {
      enum: [
        '1.18-memgraph-2.18',
        '1.18.1-memgraph-2.18.1',
        '1.19-memgraph-2.19',
        '1.20-memgraph-2.20',
        '1.20.1-memgraph-2.20.1',
        '1.21-memgraph-2.21',
        '1.22-memgraph-2.22',
        '3.0-memgraph-3.0',
      ],
      title: 'MemgraphMageImageTag',
      type: 'string',
    },
    Metadata: {
      description: 'Metadata fields describing the resource',
      properties: {
        name: {
          description:
            'Name of the entity. May only contain lowercase letters and hyphens',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        namespace: {
          description: 'Namespace the entity is defined in.',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        title: {
          default: '',
          description: 'A display name of the entity.',
          title: 'Title',
          type: 'string',
        },
        description: {
          default: '',
          description: 'A human readable description of the entity.',
          title: 'Description',
          type: 'string',
        },
        annotations: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Annotations object regarding the resource.',
          title: 'Annotations',
          type: 'object',
        },
        labels: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Labels object regarding the resource.',
          title: 'Labels',
          type: 'object',
        },
      },
      required: ['name', 'namespace'],
      title: 'Metadata',
      type: 'object',
    },
    MlflowConfig: {
      description:
        'An open source platform for the machine learning lifecycle\n\nThe [MLflow] module provides an instance of the MlFLow platform\nfor machine learning lifecycle management.\n\n[MLflow]: https://mlflow.org/',
      properties: {
        kind: {
          const: 'mlflow',
          default: 'mlflow',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chartVersion: {
          default: '1.14.0',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        uiPathPrefix: {
          $ref: '#/$defs/UrlPathSegment',
          default: '/mlflow',
          description: 'Route where the main UI is served.',
          title: 'UI path prefix',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the MLflow UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
      },
      required: ['kind'],
      title: 'MlflowConfig',
      type: 'object',
    },
    Mode: {
      description:
        'The type of deployment to be created.\n\nSee the [documentation] for details.\n\n[documentation]: https://docs.arangodb.com/stable/deploy/deployment/kubernetes/deployment-resource-reference/#specmode-string',
      enum: ['Single', 'Cluster', 'ActiveFailover'],
      title: 'Mode',
      type: 'string',
    },
    ModelUpdatePolicy: {
      description: 'Policy for updating the model.',
      enum: [
        'OnceNewDefaultVersionAvailable',
        'OnceCurrentVersionExpired',
        'NoAutoUpgrade',
      ],
      title: 'ModelUpdatePolicy',
      type: 'string',
    },
    Modules: {
      properties: {
        Databricks: {
          anyOf: [
            {
              $ref: '#/$defs/Databricks',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Configuration details for the Databricks resource within the deployment.',
          title: 'Databricks Resource Configuration',
        },
        DatabricksIN: {
          anyOf: [
            {
              $ref: '#/$defs/DatabricksIn',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Configuration details for the Databricks IN resource, typically used for inbound data processing.',
          title: 'Databricks IN Resource Configuration',
        },
        ADF: {
          anyOf: [
            {
              $ref: '#/$defs/Adf',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Configuration details for the Azure Data Factory (ADF) resource within the deployment.',
          title: 'Azure Data Factory Configuration',
        },
        Synapse: {
          anyOf: [
            {
              $ref: '#/$defs/Synapse',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Configuration details for the Azure Synapse Analytics resource within the deployment.',
          title: 'Azure Synapse Analytics Configuration',
        },
        SQLServer: {
          anyOf: [
            {
              $ref: '#/$defs/SqlServer',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Configuration details for the SQL Server resource within the deployment.',
          title: 'SQL Server Configuration',
        },
        AML: {
          anyOf: [
            {
              $ref: '#/$defs/Aml',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Configuration details for the Azure Machine Learning (AML) resource within the deployment.',
          title: 'Azure Machine Learning Configuration',
        },
        ADLS: {
          anyOf: [
            {
              $ref: '#/$defs/Adls',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Configuration details for the Azure Data Lake Storage (ADLS) resource within the deployment.',
          title: 'Azure Data Lake Storage Configuration',
        },
        OpenAI: {
          anyOf: [
            {
              $ref: '#/$defs/OpenAi',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Configuration details for the Azure OpenAI Service resource within the deployment.',
          title: 'Azure OpenAI Service Configuration',
        },
        Eventgrid: {
          anyOf: [
            {
              $ref: '#/$defs/EventGrid',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Configuration details for the Azure Event Grid resource within the deployment.',
          title: 'Azure Event Grid Configuration',
        },
        CognitiveSearch: {
          anyOf: [
            {
              $ref: '#/$defs/CognitiveSearch',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Configuration details for the Azure Cognitive Search resource within the deployment.',
          title: 'Azure Cognitive Search Configuration',
        },
        LogAnalytics: {
          anyOf: [
            {
              $ref: '#/$defs/LogAnalytics',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Configuration details for the Azure Log Analytics resource within the deployment.',
          title: 'Azure Log Analytics Configuration',
        },
        AIBox: {
          anyOf: [
            {
              $ref: '#/$defs/AIBox',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Flag indicating whether the AI Box is enabled.',
          title: 'AI Box',
        },
        AIServices: {
          anyOf: [
            {
              $ref: '#/$defs/AIServices',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Flag indicating whether AI services are enabled.',
          title: 'AI Services',
        },
      },
      title: 'Modules',
      type: 'object',
    },
    MsGraphPermission: {
      description: 'Microsoft Graph permissions',
      enum: [
        'delegated/user.read.all',
        'application/user.read.all',
        'delegated/group.read.all',
        'application/group.read.all',
        'delegated/profilephoto.read.all',
        'application/profilephoto.read.all',
      ],
      title: 'MsGraphPermission',
      type: 'string',
    },
    MysqlConfig: {
      properties: {
        config: {
          anyOf: [
            {
              $ref: '#/$defs/MysqlConfigBase',
            },
            {
              $ref: '#/$defs/StagedConfigDict_MysqlConfigBase_',
            },
          ],
          default: {
            version: '8.0.21',
            skuName: 'B_Standard_B1ms',
            storageSizeGb: 32,
            backupRetentionDays: 7,
            databases: [],
            serverParameters: null,
          },
          description: 'Configuration for the resource',
          title: 'Resource configuration',
        },
        kind: {
          const: 'mysql',
          default: 'mysql',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
      },
      required: ['kind'],
      title: 'MysqlConfig',
      type: 'object',
    },
    MysqlConfigBase: {
      description:
        'Allows provisioning Mysql databases for use in product services.',
      properties: {
        version: {
          $ref: '#/$defs/MysqlVersion',
          default: '8.0.21',
          description: 'Version of Mysql server to use.',
          title: 'Mysql version',
        },
        skuName: {
          $ref: '#/$defs/MysqlSku',
          default: 'B_Standard_B1ms',
          description:
            'SKU name to be used as base model for the Mysql server.',
          title: 'Mysql SKU',
        },
        storageSizeGb: {
          default: 32,
          description: 'Size of the Mysql storage in GB',
          title: 'Database storage capacity',
          type: 'integer',
        },
        backupRetentionDays: {
          default: 7,
          description: 'Number of days the automatic Mysql backup is kept',
          maximum: 35,
          minimum: 7,
          title: 'Backup retention days',
          type: 'integer',
        },
        databases: {
          default: [],
          description: 'Additional databases to be created in the service.',
          items: {
            $ref: '#/$defs/DatabaseName',
          },
          title: 'Databases',
          type: 'array',
          'vsc:action': 'array',
        },
        serverParameters: {
          anyOf: [
            {
              $ref: '#/$defs/MysqlServerParameters',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Configurable server parameters.',
        },
      },
      title: 'MysqlConfigBase',
      type: 'object',
    },
    MysqlDatabaseResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'mysql-db',
          default: 'mysql-db',
          title: 'Kind',
          type: 'string',
        },
        charset: {
          description: 'The character set to use for the database.',
          title: 'Character set',
          type: 'string',
        },
        collation: {
          description: 'The collation to use for the database.',
          title: 'Collation',
          type: 'string',
        },
      },
      required: ['kind', 'resourceId'],
      title: 'MysqlDatabaseResource',
      type: 'object',
    },
    MysqlServerParameters: {
      description:
        'The Mysql server parameters.\n\n[documentation]: https://www.mysql.com/docs/refman/8.0/en/server-system-variables.html',
      properties: {
        maxConnections: {
          anyOf: [
            {
              maximum: 5000,
              minimum: 25,
              type: 'integer',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'The maximum number of concurrent connections to the database server.',
          title: 'Maxconnections',
        },
        sqlGenerateInvisiblePrimaryKey: {
          anyOf: [
            {
              enum: ['ON', 'OFF'],
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Sqlgenerateinvisibleprimarykey',
        },
      },
      title: 'MysqlServerParameters',
      type: 'object',
    },
    MysqlServerResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'mysql',
          default: 'mysql',
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the Mysql server is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        fqdn: {
          description: 'Fully qualified domain name of the server.',
          title: 'FQDN',
          type: 'string',
        },
        skuName: {
          description: 'The SKU of the Mysql server.',
          title: 'SKU name',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'fqdn', 'skuName', 'resourceId'],
      title: 'MysqlServerResource',
      type: 'object',
    },
    MysqlSku: {
      description:
        'The Mysql server SKU name to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/mysql/concepts-compute-storage#compute-tiers-vcores-and-server-types',
      enum: ['B_Standard_B1ms', 'GP_Standard_D2ads_v5', 'MO_Standard_E2ds_v4'],
      title: 'MysqlSku',
      type: 'string',
    },
    MysqlVersion: {
      description:
        'The Mysql server version to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/mysql/concepts-supported-versions',
      enum: ['8.0.21'],
      title: 'MysqlVersion',
      type: 'string',
    },
    NetworkReport: {
      properties: {
        pip: {
          $ref: '#/$defs/PublicIpResource',
          description: 'Public IP address of the network.',
        },
        vnet: {
          $ref: '#/$defs/BaseResourceInfo',
          description: 'The virtual network information.',
        },
      },
      required: ['pip', 'vnet'],
      title: 'NetworkReport',
      type: 'object',
    },
    ObservabilityResources: {
      properties: {
        kusto: {
          anyOf: [
            {
              $ref: '#/$defs/KustoBackendReport',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Kusto log backend',
        },
      },
      title: 'ObservabilityResources',
      type: 'object',
    },
    OpenAIDeploymentConfig: {
      properties: {
        name: {
          description: 'Name of the deployment',
          title: 'Name',
          type: 'string',
        },
        model: {
          $ref: '#/$defs/OpenAIModel',
          description: 'Model to deploy',
        },
        updatePolicy: {
          $ref: '#/$defs/ModelUpdatePolicy',
          default: 'OnceNewDefaultVersionAvailable',
          description: 'Policy for updating the model',
        },
        maxTokensPerMinuteThousands: {
          default: 10,
          description: 'Tokens-per-Minute (TPM) measured in thousands',
          maximum: 2000,
          minimum: 1,
          title: 'tokens per minute (thousands)',
          type: 'integer',
        },
        sku: {
          $ref: '#/$defs/OpenAISku',
          default: 'Standard',
          description: 'SKU of the deployment',
        },
      },
      required: ['name', 'model'],
      title: 'OpenAIDeploymentConfig',
      type: 'object',
    },
    OpenAIEmbeddingConfig: {
      properties: {
        name: {
          description: 'Name of the deployment',
          title: 'Name',
          type: 'string',
        },
        model: {
          $ref: '#/$defs/OpenAIEmbeddingModel',
          description: 'Model to deploy',
        },
        updatePolicy: {
          $ref: '#/$defs/ModelUpdatePolicy',
          default: 'NoAutoUpgrade',
          description: 'Policy for updating the model',
        },
        maxTokensPerMinuteThousands: {
          default: 5,
          description: 'Tokens-per-Minute (TPM) measured in thousands',
          maximum: 500,
          minimum: 1,
          title: 'tokens per minute in thousands',
          type: 'integer',
        },
        sku: {
          $ref: '#/$defs/OpenAISku',
          default: 'Standard',
          description: 'SKU of the deployment',
        },
      },
      required: ['name', 'model'],
      title: 'OpenAIEmbeddingConfig',
      type: 'object',
    },
    OpenAIEmbeddingModel: {
      enum: ['text-embedding-ada-002', 'text-embedding-3-large'],
      title: 'OpenAIEmbeddingModel',
      type: 'string',
    },
    OpenAILocation: {
      description:
        'The Azure OpenAI service location to be used, see the [documentation] for details.\nModel availability may vary by Azure Region and Azure OpenAI SKU.\n[documentation]: https://learn.microsoft.com/en-us/azure/ai-services/openai/concepts/models?tabs=datazone-standard',
      enum: ['swedencentral', 'francecentral', 'polandcentral', 'westeurope'],
      title: 'OpenAILocation',
      type: 'string',
    },
    OpenAIModel: {
      enum: [
        'o3-mini-2025-01-31',
        'o1-2024-12-17',
        'o1-mini-2024-09-12',
        'gpt-4o-2024-08-06',
        'gpt-4o-2024-05-13',
        'gpt-4o-mini-2024-07-18',
        'gpt-4-turbo-2024-04-09',
        'gpt-35-turbo-1106',
      ],
      title: 'OpenAIModel',
      type: 'string',
    },
    OpenAISku: {
      enum: ['Standard', 'DataZoneStandard'],
      title: 'OpenAISku',
      type: 'string',
    },
    OpenAi: {
      properties: {
        kind: {
          const: 'open_ai',
          default: 'open_ai',
          description: 'OpenAi Resource Kind.',
          title: 'OpenAi Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'OpenAi Resource Name.',
          title: 'OpenAi Resource Name',
          type: 'string',
        },
        Location: {
          description: 'OpenAi Resource Location.',
          title: 'OpenAi Resource Location',
          type: 'string',
        },
        CustomManagedKey: {
          default: false,
          description: 'Custom Managed Key Option for Open AI.',
          title: 'Custom Managed Key Option for Open AI',
          type: 'boolean',
        },
      },
      required: ['Name', 'Location'],
      title: 'OpenAi',
      type: 'object',
    },
    OpenAiConfig: {
      properties: {
        kind: {
          const: 'openai',
          default: 'openai',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chatDeployments: {
          description: 'Chat models to deploy',
          items: {
            $ref: '#/$defs/OpenAIDeploymentConfig',
          },
          title: 'Chatdeployments',
          type: 'array',
        },
        embeddingDeployments: {
          description: 'Embedding models to deploy',
          items: {
            $ref: '#/$defs/OpenAIEmbeddingConfig',
          },
          title: 'Embeddingdeployments',
          type: 'array',
        },
        location: {
          $ref: '#/$defs/OpenAILocation',
          default: 'swedencentral',
          description:
            'Azure Region where OpenAI service should be provisioned.',
          title: 'Location',
        },
        externalAccess: {
          default: true,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
      },
      required: ['kind'],
      title: 'OpenAiConfig',
      type: 'object',
    },
    OpenTelemetryConfig: {
      properties: {
        config: {
          anyOf: [
            {
              $ref: '#/$defs/OpenTelemetryConfigBase',
            },
            {
              $ref: '#/$defs/StagedConfigDict_OpenTelemetryConfigBase_',
            },
          ],
          description: 'Configuration for the resource',
          title: 'Resource configuration',
        },
      },
      required: ['config'],
      title: 'OpenTelemetryConfig',
      type: 'object',
    },
    OpenTelemetryConfigBase: {
      properties: {
        enabled: {
          default: false,
          title: 'Enable OpenTelemetry.',
          type: 'boolean',
        },
        collectorChartVersion: {
          default: '0.109.0',
          title: 'The version of the OpenTelemetry collector chart to install.',
          type: 'string',
        },
        backend: {
          $ref: '#/$defs/OtelBackend',
          description: 'The backend for OpenTelemetry.',
          title: 'OpenTelemetry backend',
        },
      },
      title: 'OpenTelemetryConfigBase',
      type: 'object',
    },
    OtelBackend: {
      properties: {
        azureDataExplorer: {
          $ref: '#/$defs/AdxBackendConfig',
          description:
            'Enable Azure Data Explorer as a backend for OpenTelemetry.',
          title: 'Azure Data Explorer',
        },
      },
      title: 'OtelBackend',
      type: 'object',
    },
    PathMatchType: {
      enum: ['prefix', 'exact'],
      title: 'PathMatchType',
      type: 'string',
    },
    PlatformEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        manifest: {
          $ref: '#/$defs/PlatformManifest',
        },
        report: {
          $ref: '#/$defs/PlatformReport',
        },
        policies: {
          $ref: '#/$defs/PolicyReport',
          title: 'Policies.',
        },
        resources: {
          $ref: '#/$defs/ResourcesReport',
          title: 'Resources.',
        },
      },
      required: ['stackVersion', 'manifest', 'report', 'policies', 'resources'],
      title: 'PlatformEntry',
      type: 'object',
    },
    PlatformGroups: {
      properties: {
        contributors: {
          $ref: '#/$defs/GroupInfo',
        },
        readers: {
          $ref: '#/$defs/GroupInfo',
        },
      },
      required: ['contributors', 'readers'],
      title: 'PlatformGroups',
      type: 'object',
    },
    PlatformManifest: {
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'PlatformManifest',
          default: 'PlatformManifest',
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/ManifestMetadata',
          description: 'Metadata for the entity.',
          title: 'Metadata',
        },
        spec: {
          $ref: '#/$defs/PlatformSpec',
          description: 'Platform specification.',
          title: 'Platform specification',
        },
        instances: {
          $ref: '#/$defs/InstanceConfig',
          description: 'The instances of the platform.',
          title: 'Instances',
        },
        clusterSetup: {
          $ref: '#/$defs/ClusterSetup',
          description: 'The setup of the cluster.',
          title: 'Cluster setup',
        },
        portal: {
          $ref: '#/$defs/PortalConfig',
          default: {
            config: {
              location: 'westeurope',
              postgres: {
                additionalAdminGroup: {},
                backupRetentionDays: 7,
                databases: [],
                serverParameters: null,
                skuName: 'B_Standard_B2s',
                storageSize: 32768,
                version: '16',
              },
            },
            repositories: {
              portal: 'dh-developer-portal',
              templates: 'dh-scaffolder-templates',
            },
          },
        },
      },
      required: ['spec', 'metadata', 'apiVersion', 'kind'],
      title: 'PlatformManifest',
      type: 'object',
    },
    PlatformReport: {
      properties: {
        groups: {
          $ref: '#/$defs/PlatformGroups',
        },
        instances: {
          items: {
            $ref: '#/$defs/InstanceLayout',
          },
          title: 'Instances',
          type: 'array',
        },
        portal: {
          $ref: '#/$defs/PortalInfo',
        },
        datadog: {
          anyOf: [
            {
              $ref: '#/$defs/DataDogIntegrationInfo',
            },
            {
              type: 'null',
            },
          ],
          default: null,
        },
      },
      required: ['groups', 'instances', 'portal'],
      title: 'PlatformReport',
      type: 'object',
    },
    PlatformSpec: {
      properties: {
        owner: {
          description:
            'AAD object ID or display name for the group owning the entity.',
          format: 'uuid',
          title: 'Owner group',
          type: 'string',
          'ui:field': 'AzureGroupSearch',
          'vsc:picker': 'aad-group',
        },
        primaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description: 'AAD object id or email of the primary contact person.',
          title: 'Primary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        secondaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description:
            'AAD object id or email of the secondary contact person.',
          title: 'Secondary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        uniqueKey: {
          $ref: '#/$defs/UniqueKeyType',
          description: 'A unique identifier for the entity.',
          title: 'Unique key.',
          'ui:disabled': true,
        },
        lifecycle: {
          $ref: '#/$defs/argus__provisioning__models__common__registry__Lifecycle',
          default: 'production',
          description: 'Lifecycle of the entity.',
          title: 'Entity lifecycle stage.',
        },
        featureFlags: {
          default: [],
          description:
            'Feature flags allow op-ting into experimental functionality in the stack',
          items: {
            $ref: '#/$defs/FeatureFlag',
          },
          title: 'Feature flags.',
          type: 'array',
          'vsc:action': 'array',
        },
        devTeams: {
          description: 'Platform teams assigned platform level roles.',
          items: {
            $ref: '#/$defs/GitHubTeamConfig',
          },
          title: 'Development teams',
          type: 'array',
        },
        teams: {
          description:
            'Teams that are assigned permissions on the platform instance.',
          items: {
            $ref: '#/$defs/PlatformTeam',
          },
          title: 'Platform teams',
          type: 'array',
        },
      },
      required: ['owner', 'primaryContact', 'secondaryContact', 'uniqueKey'],
      title: 'PlatformSpec',
      type: 'object',
    },
    PlatformTeam: {
      properties: {
        name: {
          title: 'Name',
          type: 'string',
        },
        description: {
          title: 'Description',
          type: 'string',
        },
        role: {
          $ref: '#/$defs/PlatformTeamRole',
        },
        objectId: {
          format: 'uuid',
          title: 'Object ID',
          type: 'string',
        },
      },
      required: ['name', 'description', 'role', 'objectId'],
      title: 'PlatformTeam',
      type: 'object',
    },
    PlatformTeamRole: {
      enum: [
        'admin',
        'infra-admin',
        'developer',
        'stakeholder',
        'firefighter',
        'product-owner',
      ],
      title: 'PlatformTeamRole',
      type: 'string',
    },
    PolicyReport: {
      properties: {
        permissions: {
          description:
            'List of role assignments defined in the synthesized stack.',
          items: {
            $ref: '#/$defs/RoleAssignmentInfo',
          },
          title: 'Role Assignments',
          type: 'array',
        },
      },
      required: ['permissions'],
      title: 'PolicyReport',
      type: 'object',
    },
    PolicyReportEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        report: {
          $ref: '#/$defs/PolicyReport',
        },
      },
      required: ['stackVersion', 'report'],
      title: 'PolicyReportEntry',
      type: 'object',
    },
    PortNumber: {
      maximum: 65535,
      minimum: 1,
      type: 'integer',
    },
    PortalConfig: {
      properties: {
        config: {
          anyOf: [
            {
              $ref: '#/$defs/PortalConfigBase',
            },
            {
              $ref: '#/$defs/StagedConfigDict_PortalConfigBase_',
            },
          ],
          description: 'Configuration for the resource',
          title: 'Resource configuration',
        },
        repositories: {
          $ref: '#/$defs/PortalRepositories',
          default: {
            templates: 'dh-scaffolder-templates',
            portal: 'dh-developer-portal',
          },
        },
      },
      required: ['config'],
      title: 'PortalConfig',
      type: 'object',
    },
    PortalConfigBase: {
      properties: {
        postgres: {
          $ref: '#/$defs/PostgresConfigBase',
          default: {
            version: '16',
            skuName: 'B_Standard_B2s',
            storageSize: 32768,
            databases: [],
            backupRetentionDays: 7,
            additionalAdminGroup: {},
            serverParameters: null,
          },
        },
        location: {
          $ref: '#/$defs/AzureRegion',
          default: 'westeurope',
        },
      },
      title: 'PortalConfigBase',
      type: 'object',
    },
    PortalInfo: {
      properties: {
        templateRepository: {
          title: 'Name of the repository publishing templates',
          type: 'string',
        },
      },
      required: ['templateRepository'],
      title: 'PortalInfo',
      type: 'object',
    },
    PortalRepositories: {
      properties: {
        templates: {
          default: 'dh-scaffolder-templates',
          title: 'Name of the repo for Software Templates',
          type: 'string',
        },
        portal: {
          default: 'dh-developer-portal',
          title: 'Name of the repo deploying the orchestration portal',
          type: 'string',
        },
      },
      title: 'PortalRepositories',
      type: 'object',
    },
    PortalResources: {
      properties: {
        techdocsContainer: {
          $ref: '#/$defs/StorageContainerResource',
          title: 'TechDocs container',
        },
        templatesContainer: {
          anyOf: [
            {
              $ref: '#/$defs/StorageContainerResource',
            },
            {
              type: 'null',
            },
          ],
          title: 'Templates container',
        },
      },
      required: ['techdocsContainer', 'templatesContainer'],
      title: 'PortalResources',
      type: 'object',
    },
    PostgresAzureExtension: {
      enum: [
        'address_standardizer',
        'address_standardizer_data_us',
        'age',
        'amcheck',
        'anon',
        'auto_explain',
        'azure',
        'azure_ai',
        'azure_local_ai',
        'azure_storage',
        'azure_keyvault',
        'azurelib',
        'bloom',
        'btree_gin',
        'btree_gist',
        'citext',
        'cube',
        'dblink',
        'dict_int',
        'dict_xsyn',
        'earthdistance',
        'fuzzystrmatch',
        'hll',
        'hstore',
        'hypopg',
        'intagg',
        'intarray',
        'isn',
        'lo',
        'login_hook',
        'ltree',
        'oracle_fdw',
        'orafce',
        'pageinspect',
        'pg_buffercache',
        'pg_cron',
        'pg_diskann',
        'pg_failover_slots',
        'pg_freespacemap',
        'pg_hint_plan',
        'pg_partman',
        'pg_partman_bgw',
        'pg_prewarm',
        'pg_repack',
        'pgrouting',
        'pgrowlocks',
        'pg_squeeze',
        'pg_stat_statements',
        'pgstattuple',
        'pg_trgm',
        'pg_visibility',
        'pgaudit',
        'pgcrypto',
        'pglogical',
        'plpgsql',
        'plv8',
        'postgis',
        'postgis_raster',
        'postgis_sfcgal',
        'postgis_tiger_geocoder',
        'postgis_topology',
        'postgres_fdw',
        'postgres_protobuf',
        'semver',
        'session_variable',
        'sslinfo',
        'tablefunc',
        'tdigest',
        'tds_fdw',
        'timescaledb',
        'topn',
        'tsm_system_rows',
        'tsm_system_time',
        'unaccent',
        'uuid-ossp',
        'vector',
        'wal2json',
      ],
      type: 'string',
    },
    PostgresConfig: {
      properties: {
        config: {
          anyOf: [
            {
              $ref: '#/$defs/PostgresConfigBase',
            },
            {
              $ref: '#/$defs/StagedConfigDict_PostgresConfigBase_',
            },
          ],
          default: {
            version: '14',
            skuName: 'B_Standard_B1ms',
            storageSize: 32768,
            databases: [],
            backupRetentionDays: 7,
            additionalAdminGroup: {},
            serverParameters: null,
          },
          description: 'Configuration for the resource',
          title: 'Resource configuration',
        },
        kind: {
          const: 'postgres',
          default: 'postgres',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
      },
      required: ['kind'],
      title: 'PostgresConfig',
      type: 'object',
    },
    PostgresConfigBase: {
      description:
        'Allows provisioning Postgres databases for use in product services.',
      properties: {
        version: {
          $ref: '#/$defs/PostgresVersion',
          default: '14',
          description: 'Version of postgres server to use.',
          title: 'Postgres version',
        },
        skuName: {
          $ref: '#/$defs/PostgresSku',
          default: 'B_Standard_B1ms',
          description:
            'SKU name to be used as base model for the Postgres server.',
          title: 'Postgres SKU',
        },
        storageSize: {
          $ref: '#/$defs/PostgresStorageSize',
          default: 32768,
          description: 'Size of the Postgres storage in MB',
          title: 'Database storage capacity',
        },
        databases: {
          default: [],
          description: 'Additional databases to be created in the service.',
          items: {
            $ref: '#/$defs/DatabaseName',
          },
          title: 'Provisioned databases',
          type: 'array',
          'vsc:action': 'array',
        },
        backupRetentionDays: {
          default: 7,
          description: 'Number of days the automatic Postgres backup is kept',
          maximum: 35,
          minimum: 7,
          title: 'Backup retention',
          type: 'integer',
        },
        additionalAdminGroup: {
          additionalProperties: {
            format: 'uuid',
            type: 'string',
          },
          default: {},
          deprecated: true,
          description:
            'Additional administrators to be mapped to Azure Groups.',
          title: 'Additionaladmingroup',
          type: 'object',
        },
        serverParameters: {
          anyOf: [
            {
              $ref: '#/$defs/PostgresServerParameters',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Configurable server parameters.',
        },
      },
      title: 'PostgresConfigBase',
      type: 'object',
    },
    PostgresDatabaseResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'postgres-db',
          default: 'postgres-db',
          title: 'Kind',
          type: 'string',
        },
        charset: {
          description: 'The character set to use for the database.',
          title: 'Character set',
          type: 'string',
        },
        collation: {
          description: 'The collation to use for the database.',
          title: 'Collation',
          type: 'string',
        },
      },
      required: ['kind', 'resourceId'],
      title: 'PostgresDatabaseResource',
      type: 'object',
    },
    PostgresServerParameters: {
      description:
        'The Postgres server parameters. See [documentation]\n\n[documentation]: https://learn.microsoft.com/en-us/azure/postgresql/flexible-server/concepts-server-parameters',
      properties: {
        maxConnections: {
          anyOf: [
            {
              maximum: 5000,
              minimum: 25,
              type: 'integer',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'The maximum number of concurrent connections to the database server.',
          title: 'Maxconnections',
        },
        azureExtensions: {
          anyOf: [
            {
              items: {
                $ref: '#/$defs/PostgresAzureExtension',
              },
              type: 'array',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'The list of extensions to be installed on the database server.',
          title: 'Azureextensions',
        },
      },
      title: 'PostgresServerParameters',
      type: 'object',
    },
    PostgresServerResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'postgres',
          default: 'postgres',
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the Postgres server is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        fqdn: {
          description: 'Fully qualified domain name of the server.',
          title: 'FQDN',
          type: 'string',
        },
        skuName: {
          description: 'The SKU of the Postgres server.',
          title: 'SKU name',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'fqdn', 'skuName', 'resourceId'],
      title: 'PostgresServerResource',
      type: 'object',
    },
    PostgresSku: {
      description:
        'The Postgres server SKU name to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/postgresql/flexible-server/concepts-compute-storage#compute-tiers-vcores-and-server-types',
      enum: ['B_Standard_B1ms', 'B_Standard_B2s', 'GP_Standard_D2ds_v5'],
      title: 'PostgresSku',
      type: 'string',
    },
    PostgresStorageSize: {
      enum: [
        32768, 65536, 131072, 262144, 524288, 1048576, 2097152, 4193280,
        4194304, 8388608, 16777216, 33553408,
      ],
      type: 'integer',
    },
    PostgresVersion: {
      description:
        'The Postgres server version to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/postgresql/flexible-server/concepts-supported-versions',
      enum: ['14', '16'],
      title: 'PostgresVersion',
      type: 'string',
    },
    ProductCellManifest: {
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'ProductCellManifest',
          default: 'ProductCellManifest',
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/ManifestMetadata',
          description: 'Metadata for the entity.',
          title: 'Metadata',
        },
        spec: {
          $ref: '#/$defs/ProductCellSpec',
          description: 'Platform specification.',
          title: 'Platform specification',
        },
        azure: {
          $ref: '#/$defs/StagedConfigDict_SubscriptionList_',
          description: 'The Azure subscriptions that belong to this cell.',
        },
        clusterSetup: {
          $ref: '#/$defs/ClusterSetup',
          description: 'The setup of the cluster.',
          title: 'Cluster setup',
        },
      },
      required: ['spec', 'metadata', 'apiVersion', 'kind'],
      title: 'ProductCellManifest',
      type: 'object',
    },
    ProductCellSpec: {
      properties: {
        owner: {
          description:
            'AAD object ID or display name for the group owning the entity.',
          format: 'uuid',
          title: 'Owner group',
          type: 'string',
          'ui:field': 'AzureGroupSearch',
          'vsc:picker': 'aad-group',
        },
        primaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description: 'AAD object id or email of the primary contact person.',
          title: 'Primary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        secondaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description:
            'AAD object id or email of the secondary contact person.',
          title: 'Secondary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        uniqueKey: {
          $ref: '#/$defs/UniqueKeyType',
          description: 'A unique identifier for the entity.',
          title: 'Unique key.',
          'ui:disabled': true,
        },
        lifecycle: {
          $ref: '#/$defs/argus__provisioning__models__common__registry__Lifecycle',
          default: 'production',
          description: 'Lifecycle of the entity.',
          title: 'Entity lifecycle stage.',
        },
        featureFlags: {
          default: [],
          description:
            'Feature flags allow op-ting into experimental functionality in the stack',
          items: {
            $ref: '#/$defs/FeatureFlag',
          },
          title: 'Feature flags.',
          type: 'array',
          'vsc:action': 'array',
        },
        instance: {
          description: 'Platform instance name.',
          title: 'Platform instance',
          type: 'string',
        },
      },
      required: [
        'owner',
        'primaryContact',
        'secondaryContact',
        'uniqueKey',
        'instance',
      ],
      title: 'ProductCellSpec',
      type: 'object',
    },
    ProductEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        output: {
          $ref: '#/$defs/ProductReport',
        },
        input: {
          $ref: '#/$defs/ProductManifest',
        },
        policies: {
          $ref: '#/$defs/PolicyReport',
          title: 'Policies.',
        },
      },
      required: ['stackVersion', 'output', 'input', 'policies'],
      title: 'ProductEntry',
      type: 'object',
    },
    ProductManifest: {
      description: 'Top level configuration for Argus products.',
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'ProductManifest',
          default: 'ProductManifest',
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/ManifestMetadata',
          description: 'Metadata for the entity.',
          title: 'Metadata',
        },
        spec: {
          $ref: '#/$defs/ProductSpec',
        },
        capabilities: {
          default: [],
          description: 'Product capabilities',
          items: {
            discriminator: {
              mapping: {
                mysql: '#/$defs/MysqlConfig',
                'object-store': '#/$defs/StorageConfig',
                postgres: '#/$defs/PostgresConfig',
              },
              propertyName: 'kind',
            },
            oneOf: [
              {
                $ref: '#/$defs/MysqlConfig',
              },
              {
                $ref: '#/$defs/PostgresConfig',
              },
              {
                $ref: '#/$defs/StorageConfig',
              },
            ],
            title: 'Any Capability Config',
          },
          title: 'Product capabilities',
          type: 'array',
        },
        modules: {
          default: [],
          description:
            'Modules provide functionalities and experiences inside a product context.',
          items: {
            description: 'Any Module Config',
            discriminator: {
              mapping: {
                'ai-multi-service': '#/$defs/AzureAIServicesConfig',
                'ai-search': '#/$defs/AISearchServiceConfigStandard',
                'ai-search-basic': '#/$defs/AISearchServiceConfigBasic',
                arango: '#/$defs/ArangoConfig',
                cloudbeaver: '#/$defs/CloudBeaverConfig',
                dagster: '#/$defs/DagsterConfig',
                jupyter: '#/$defs/JupyterHubConfig',
                memgraph: '#/$defs/MemgraphConfig',
                mlflow: '#/$defs/MlflowConfig',
                openai: '#/$defs/OpenAiConfig',
                redis: '#/$defs/RedisConfig',
                superset: '#/$defs/SupersetConfig',
              },
              propertyName: 'kind',
            },
            oneOf: [
              {
                $ref: '#/$defs/DagsterConfig',
              },
              {
                $ref: '#/$defs/MlflowConfig',
              },
              {
                $ref: '#/$defs/MemgraphConfig',
              },
              {
                $ref: '#/$defs/JupyterHubConfig',
              },
              {
                $ref: '#/$defs/ArangoConfig',
              },
              {
                $ref: '#/$defs/SupersetConfig',
              },
              {
                $ref: '#/$defs/CloudBeaverConfig',
              },
              {
                $ref: '#/$defs/OpenAiConfig',
              },
              {
                $ref: '#/$defs/AzureAIServicesConfig',
              },
              {
                $ref: '#/$defs/AISearchServiceConfigBasic',
              },
              {
                $ref: '#/$defs/AISearchServiceConfigStandard',
              },
              {
                $ref: '#/$defs/RedisConfig',
              },
            ],
            title: 'Any Module Config',
          },
          title: 'Product modules',
          type: 'array',
        },
        routing: {
          $ref: '#/$defs/RoutingConfig',
          default: {
            exposedServices: [],
            linkedProducts: [],
            subDomainOverride: null,
            token: null,
          },
          description: 'Manage traffic in and out of a product namespace.',
          title: 'Routing',
        },
        codeLocations: {
          description: 'List of code locations for the product.',
          items: {
            $ref: '#/$defs/CodeLocation',
          },
          title: 'Code locations',
          type: 'array',
        },
        application: {
          $ref: '#/$defs/ApplicationConfig',
          default: {
            requiredResourceAccess: [],
            extraRedirectUrls: {},
          },
          description: 'Application configuration for the product.',
          title: 'Application',
        },
        extensions: {
          $ref: '#/$defs/ExtensionConfig',
          default: {
            appstore: [],
            dsp: null,
            aigateway: null,
          },
          description: 'List of extensions for the product. [experimental]',
          title: 'Extensions [experimental]',
        },
      },
      required: ['spec', 'metadata', 'apiVersion', 'kind'],
      title: 'ProductManifest',
      type: 'object',
    },
    ProductReport: {
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          default: 'core.argus.ai/v1alpha1',
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'ProductCatalogEntry',
          default: 'ProductCatalogEntry',
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/Metadata',
          description: 'Basic metadata about the product.',
          title: 'Product metadata.',
        },
        identity: {
          $ref: '#/$defs/IdentityInfo',
          description:
            'Default identity to use for accessing product resources.',
          title: 'Product identity.',
        },
        k8sRelease: {
          anyOf: [
            {
              $ref: '#/$defs/K8sReleaseInfo',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Basic information about K8s environment for the product.',
          title: 'K8s release info.',
        },
        resources: {
          description: 'Azure resources provisioned for the product.',
          items: {
            description: 'Any Resource',
            discriminator: {
              mapping: {
                acr: '#/$defs/AcrResource',
                'cognitive-deployment': '#/$defs/CognitiveDeploymentsResource',
                'cognitive-services': '#/$defs/CognitiveAccountResource',
                'key-vault': '#/$defs/KeyVaultResource',
                'kusto-cluster': '#/$defs/KustoClusterResource',
                mysql: '#/$defs/MysqlServerResource',
                'mysql-db': '#/$defs/MysqlDatabaseResource',
                postgres: '#/$defs/PostgresServerResource',
                'postgres-db': '#/$defs/PostgresDatabaseResource',
                'resource-group': '#/$defs/ResourceGroupResource',
                'storage-account': '#/$defs/StorageAccountResource',
                'storage-container': '#/$defs/StorageContainerResource',
              },
              propertyName: 'kind',
            },
            oneOf: [
              {
                $ref: '#/$defs/AcrResource',
              },
              {
                $ref: '#/$defs/StorageAccountResource',
              },
              {
                $ref: '#/$defs/KeyVaultResource',
              },
              {
                $ref: '#/$defs/PostgresServerResource',
              },
              {
                $ref: '#/$defs/StorageContainerResource',
              },
              {
                $ref: '#/$defs/PostgresDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlServerResource',
              },
              {
                $ref: '#/$defs/ResourceGroupResource',
              },
              {
                $ref: '#/$defs/CognitiveAccountResource',
              },
              {
                $ref: '#/$defs/CognitiveDeploymentsResource',
              },
              {
                $ref: '#/$defs/KustoClusterResource',
              },
            ],
            title: 'Any Resource',
          },
          title: 'Product resources.',
          type: 'array',
        },
        routing: {
          $ref: '#/$defs/RoutingReport',
          description: 'List of exposed services.',
          title: 'Product routing.',
        },
      },
      required: ['kind', 'metadata', 'identity', 'k8sRelease', 'resources'],
      title: 'ProductReport',
      type: 'object',
    },
    ProductSpec: {
      properties: {
        owner: {
          description:
            'AAD object ID or display name for the group owning the entity.',
          format: 'uuid',
          title: 'Owner group',
          type: 'string',
          'ui:field': 'AzureGroupSearch',
          'vsc:picker': 'aad-group',
        },
        primaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description: 'AAD object id or email of the primary contact person.',
          title: 'Primary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        secondaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description:
            'AAD object id or email of the secondary contact person.',
          title: 'Secondary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        uniqueKey: {
          $ref: '#/$defs/UniqueKeyType',
          description: 'A unique identifier for the entity.',
          title: 'Unique key.',
          'ui:disabled': true,
        },
        lifecycle: {
          $ref: '#/$defs/argus__provisioning__models__common__registry__Lifecycle',
          default: 'production',
          description: 'Lifecycle of the entity.',
          title: 'Entity lifecycle stage.',
        },
        featureFlags: {
          default: [],
          description:
            'Feature flags allow op-ting into experimental functionality in the stack',
          items: {
            $ref: '#/$defs/FeatureFlag',
          },
          title: 'Feature flags.',
          type: 'array',
          'vsc:action': 'array',
        },
        workspace: {
          description: 'Name of the workspace this product is managed by.',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'Workspace name',
          type: 'string',
        },
        resourceQuotaRequests: {
          $ref: '#/$defs/K8sResources',
          description: 'Requested Kubernetes resources for product namespace.',
          title: 'Resource requests',
        },
        resourceQuotaLimits: {
          $ref: '#/$defs/K8sResources',
          description:
            'Upper limit for Kubernetes resources for product namespace.',
          title: 'Resource limits',
        },
        location: {
          $ref: '#/$defs/AzureRegion',
          default: 'westeurope',
          description: 'Azure region where resources should be provisioned.',
          title: 'Location',
        },
      },
      required: [
        'owner',
        'primaryContact',
        'secondaryContact',
        'uniqueKey',
        'workspace',
      ],
      title: 'ProductSpec',
      type: 'object',
    },
    PublicIpResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'public-ip',
          default: 'public-ip',
          title: 'Kind',
          type: 'string',
        },
        publicIp: {
          description: 'Public IP address of the network.',
          title: 'Publicip',
          type: 'string',
        },
      },
      required: ['resourceId', 'publicIp'],
      title: 'PublicIpResource',
      type: 'object',
    },
    RFC1035Name: {
      maxLength: 62,
      minLength: 1,
      pattern: '^[a-z]+(?:-?[a-z0-9]+)+$',
      type: 'string',
    },
    RedisArchitecture: {
      enum: ['standalone', 'replication'],
      title: 'RedisArchitecture',
      type: 'string',
    },
    RedisConfig: {
      properties: {
        kind: {
          const: 'redis',
          default: 'redis',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        architecture: {
          $ref: '#/$defs/RedisArchitecture',
          default: 'standalone',
          title: 'Architecture',
        },
        version: {
          default: '19.6.0',
          title: 'Redis Helm chart version',
          type: 'string',
        },
        imageTag: {
          default: '7.2.0',
          title: 'Redis version to use',
          type: 'string',
        },
        storageSize: {
          default: '1Gi',
          title: 'Redis persistence volume size',
          type: 'string',
        },
      },
      required: ['kind'],
      title: 'RedisConfig',
      type: 'object',
    },
    Region: {
      description: 'Region where the lab resources are deployed',
      enum: ['northeurope', 'chinanorth3'],
      title: 'Region',
      type: 'string',
    },
    RegistryAgentReport: {
      properties: {
        targetCell: {
          $ref: '#/$defs/InstanceCellRef',
          description: 'The cell the agent can deploy to.',
          title: 'Target cell',
        },
        objectId: {
          description: 'The object ID of the service principal.',
          title: 'Object ID',
          type: 'string',
        },
        repositories: {
          description: 'The repositories the service principal has access to.',
          items: {
            type: 'string',
          },
          title: 'Repositories',
          type: 'array',
        },
        environmentName: {
          description:
            'The name of the environment the service principal is used in.',
          title: 'Environment name',
          type: 'string',
        },
      },
      required: ['targetCell', 'objectId', 'environmentName'],
      title: 'RegistryAgentReport',
      type: 'object',
    },
    ResourceAccessConfig_Literal_MS_GRAPH__MsGraphPermission_: {
      properties: {
        resource: {
          const: 'microsoft-graph',
          description: 'The Azure resource for which the access is configured.',
          title: 'Azure resource',
          type: 'string',
        },
        resourceAccess: {
          description: 'Access configuration for the selected resource.',
          items: {
            $ref: '#/$defs/MsGraphPermission',
          },
          title: 'Resource access',
          type: 'array',
          uniqueItems: true,
        },
      },
      required: ['resource'],
      title:
        "ResourceAccessConfig[Literal[<AzureResource.MS_GRAPH: 'microsoft-graph'>], MsGraphPermission]",
      type: 'object',
    },
    ResourceGroupResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        location: {
          description: 'Azure region where the resource group is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        kind: {
          const: 'resource-group',
          default: 'resource-group',
          title: 'Kind',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'resourceId'],
      title: 'ResourceGroupResource',
      type: 'object',
    },
    ResourcesReport: {
      properties: {
        resources: {
          description: 'List of azure resources provisioned in the stack.',
          items: {
            description: 'Any Resource',
            discriminator: {
              mapping: {
                acr: '#/$defs/AcrResource',
                'cognitive-deployment': '#/$defs/CognitiveDeploymentsResource',
                'cognitive-services': '#/$defs/CognitiveAccountResource',
                'key-vault': '#/$defs/KeyVaultResource',
                'kusto-cluster': '#/$defs/KustoClusterResource',
                mysql: '#/$defs/MysqlServerResource',
                'mysql-db': '#/$defs/MysqlDatabaseResource',
                postgres: '#/$defs/PostgresServerResource',
                'postgres-db': '#/$defs/PostgresDatabaseResource',
                'resource-group': '#/$defs/ResourceGroupResource',
                'storage-account': '#/$defs/StorageAccountResource',
                'storage-container': '#/$defs/StorageContainerResource',
              },
              propertyName: 'kind',
            },
            oneOf: [
              {
                $ref: '#/$defs/AcrResource',
              },
              {
                $ref: '#/$defs/StorageAccountResource',
              },
              {
                $ref: '#/$defs/KeyVaultResource',
              },
              {
                $ref: '#/$defs/PostgresServerResource',
              },
              {
                $ref: '#/$defs/StorageContainerResource',
              },
              {
                $ref: '#/$defs/PostgresDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlServerResource',
              },
              {
                $ref: '#/$defs/ResourceGroupResource',
              },
              {
                $ref: '#/$defs/CognitiveAccountResource',
              },
              {
                $ref: '#/$defs/CognitiveDeploymentsResource',
              },
              {
                $ref: '#/$defs/KustoClusterResource',
              },
            ],
            title: 'Any Resource',
          },
          title: 'Azure resources',
          type: 'array',
        },
        resourceLocations: {
          additionalProperties: {
            type: 'string',
          },
          description:
            'Mapping of a node in the construct tree to the resource id.',
          title: 'Resource locations',
          type: 'object',
        },
      },
      required: ['resources', 'resourceLocations'],
      title: 'ResourcesReport',
      type: 'object',
    },
    ResourcesReportEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        report: {
          $ref: '#/$defs/ResourcesReport',
        },
      },
      required: ['stackVersion', 'report'],
      title: 'ResourcesReportEntry',
      type: 'object',
    },
    RoleAssignmentInfo: {
      properties: {
        roleDefinitionName: {
          title: 'Roledefinitionname',
          type: 'string',
        },
        roleDefinitionId: {
          title: 'Roledefinitionid',
          type: 'string',
        },
        resourceId: {
          description:
            'Azure Resource Manager ID of the resource this role applies to.',
          title: 'Resource id.',
          type: 'string',
        },
        principalId: {
          title: 'Principalid',
          type: 'string',
        },
        principalType: {
          title: 'Principaltype',
          type: 'string',
        },
        description: {
          description:
            'A short description on why the role assignment is required.',
          title: 'Motivation',
          type: 'string',
        },
      },
      required: [
        'roleDefinitionName',
        'roleDefinitionId',
        'resourceId',
        'principalId',
        'principalType',
        'description',
      ],
      title: 'RoleAssignmentInfo',
      type: 'object',
    },
    RoutingConfig: {
      properties: {
        exposedServices: {
          description: 'Product services exposed to clients or other products.',
          items: {
            $ref: '#/$defs/ExposedServiceConfig',
          },
          title: 'Exposed services',
          type: 'array',
        },
        linkedProducts: {
          description:
            'Products that are allowed to invoke services from this product.',
          items: {
            $ref: '#/$defs/EntityName',
          },
          title: 'Linked products',
          type: 'array',
        },
        subDomainOverride: {
          anyOf: [
            {
              $ref: '#/$defs/SubDomain',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Custom sub-domain for apps and services deployed in the product.',
          title: 'Subdomain override',
        },
        token: {
          anyOf: [
            {
              $ref: '#/$defs/StagedConfigDict_TokenConfig_',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Token configuration for the product.',
          title: 'Token',
        },
      },
      title: 'RoutingConfig',
      type: 'object',
    },
    RoutingReport: {
      properties: {
        logicalStage: {
          $ref: '#/$defs/LogicalStage',
          description: 'The logical stage this routing report is for.',
          title: 'Logical stage',
        },
        services: {
          items: {
            $ref: '#/$defs/ExposedServiceEntry',
          },
          title: 'Services',
          type: 'array',
        },
        egressListeners: {
          description: 'Egress listeners.',
          items: {
            $ref: '#/$defs/EgressListenerConfig',
          },
          title: 'Egresslisteners',
          type: 'array',
        },
      },
      required: ['logicalStage', 'services'],
      title: 'RoutingReport',
      type: 'object',
    },
    RoutingRule: {
      properties: {
        path: {
          $ref: '#/$defs/UrlPathSegment',
          title: 'Matched path segment.',
        },
        pathMatch: {
          $ref: '#/$defs/PathMatchType',
          default: 'prefix',
          title: 'Path matcher type.',
        },
        pathRewrite: {
          anyOf: [
            {
              $ref: '#/$defs/UrlPathSegment',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Rewrite matched path before sending request to downstream service.',
          title: 'Path rewrite',
        },
        allowedGroups: {
          default: [],
          description: 'Restrict access to this route to specific AAD groups.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
        timeout: {
          default: 15,
          maximum: 90,
          minimum: 1,
          title: 'Timeout in seconds: e.g. 5',
          type: 'integer',
        },
        unsafeDisableAuth: {
          default: false,
          description: 'UNSAFE - Disable authentication for this route.',
          title: 'Disable authentication',
          type: 'boolean',
        },
        autoHostRewrite: {
          anyOf: [
            {
              type: 'boolean',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Rewrite the host header to match the target service host.',
          title: 'Auto host rewrite',
        },
      },
      required: ['path'],
      title: 'RoutingRule',
      type: 'object',
    },
    RuleSet: {
      enum: ['base', 'strict'],
      title: 'RuleSet',
      type: 'string',
    },
    ServerlessConfiguration: {
      enum: ['privateEndpoint', 'subnet', ''],
      title: 'ServerlessConfiguration',
      type: 'string',
    },
    ServiceLevel: {
      description: 'Service level associated with the deployment',
      enum: ['Support Offering 1', 'Support Offering 2', 'Support Offering 3'],
      title: 'ServiceLevel',
      type: 'string',
    },
    ServiceLevelAgreement: {
      description: 'Service level agreement associated with the deployment',
      enum: ['Bronze', 'Silver', 'Gold'],
      title: 'ServiceLevelAgreement',
      type: 'string',
    },
    SharedGroupsReport: {
      properties: {
        workspace_owner: {
          $ref: '#/$defs/GroupInfo',
        },
        product_owner: {
          $ref: '#/$defs/GroupInfo',
        },
        workspace_identities: {
          $ref: '#/$defs/GroupInfo',
        },
        platform_contributors: {
          $ref: '#/$defs/GroupInfo',
        },
        basf_global_members: {
          $ref: '#/$defs/GroupInfo',
        },
      },
      required: [
        'workspace_owner',
        'product_owner',
        'workspace_identities',
        'platform_contributors',
        'basf_global_members',
      ],
      title: 'SharedGroupsReport',
      type: 'object',
    },
    SqlResource: {
      properties: {
        kind: {
          const: 'sql_resource',
          default: 'sql_resource',
          description: 'Sql Resource Resource Kind',
          title: 'Sql Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'Sql Resource Name',
          title: 'Sql Resource Name',
          type: 'string',
        },
      },
      required: ['Name'],
      title: 'SqlResource',
      type: 'object',
    },
    SqlServer: {
      properties: {
        kind: {
          const: 'sql_server',
          default: 'sql_server',
          description: 'SqlServer Resource Kind',
          title: 'SqlServer Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'SqlServer Resource Name',
          title: 'SqlServer Resource Name',
          type: 'string',
        },
        ElasticPool: {
          default: false,
          description: 'elasticPool option enabled',
          title: 'elasticPool option enabled',
          type: 'boolean',
        },
        SQLAuditing: {
          default: false,
          description: 'sqlAuditing option enabled',
          title: 'sqlAuditing option enabled',
          type: 'boolean',
        },
        Repo: {
          default: 0,
          description: '# of repos for resource',
          maximum: 10,
          minimum: 0,
          title: '# of repos for resource',
          type: 'integer',
        },
        RepoFork: {
          default: false,
          description: 'Repo Fork option enabled',
          title: 'Repo Fork option enabled',
          type: 'boolean',
        },
        DiagnosticSettingsLaw: {
          default: false,
          description: 'Diagnostic Settings Law',
          title: 'Diagnostic Settings Law',
          type: 'boolean',
        },
        DiagnosticSettingsSta: {
          default: false,
          description: 'Diagnostic Settings Sta',
          title: 'Diagnostic Settings Sta',
          type: 'boolean',
        },
        Logs: {
          default: [],
          description: 'Logs for ADF resource',
          items: {
            type: 'string',
          },
          title: 'Logs for ADF resource',
          type: 'array',
        },
        Metrics: {
          default: [],
          description: 'Metrics for ADF resource',
          items: {
            type: 'string',
          },
          title: 'Metrics for ADF resource',
          type: 'array',
        },
        Databases: {
          default: [],
          description: 'List of SQL DBs deployed in server',
          items: {
            $ref: '#/$defs/SqlResource',
          },
          title: 'SQL DBs deployed in server',
          type: 'array',
        },
        Serverless: {
          default: [],
          description: 'List of serverless deployed in server',
          items: {
            $ref: '#/$defs/SqlResource',
          },
          title: 'Serverless deployed in server',
          type: 'array',
        },
        SQLPools: {
          default: [],
          description: 'List of SQL Pools deployed in server',
          items: {
            $ref: '#/$defs/SqlResource',
          },
          title: 'SQL Pools deployed in server',
          type: 'array',
        },
      },
      required: ['Name'],
      title: 'SqlServer',
      type: 'object',
    },
    StagedConfigDict_CertManagerConfigBase_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/CertManagerConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/CertManagerConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[CertManagerConfigBase]',
      type: 'object',
    },
    StagedConfigDict_InstanceSpec_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/InstanceSpec',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/InstanceSpec',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[InstanceSpec]',
      type: 'object',
    },
    StagedConfigDict_MysqlConfigBase_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/MysqlConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/MysqlConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[MysqlConfigBase]',
      type: 'object',
    },
    StagedConfigDict_OpenTelemetryConfigBase_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/OpenTelemetryConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/OpenTelemetryConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[OpenTelemetryConfigBase]',
      type: 'object',
    },
    StagedConfigDict_PortalConfigBase_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/PortalConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/PortalConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[PortalConfigBase]',
      type: 'object',
    },
    StagedConfigDict_PostgresConfigBase_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/PostgresConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/PostgresConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[PostgresConfigBase]',
      type: 'object',
    },
    StagedConfigDict_StorageConfigBase_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/StorageConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/StorageConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[StorageConfigBase]',
      type: 'object',
    },
    StagedConfigDict_SubscriptionList_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/SubscriptionList',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/SubscriptionList',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[SubscriptionList]',
      type: 'object',
    },
    StagedConfigDict_TokenConfig_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/TokenConfig',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/TokenConfig',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[TokenConfig]',
      type: 'object',
    },
    StorageAccountResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'storage-account',
          default: 'storage-account',
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the storage account is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        hnsEnabled: {
          title: 'Hnsenabled',
          type: 'boolean',
        },
        replicationType: {
          title: 'Replicationtype',
          type: 'string',
        },
      },
      required: [
        'kind',
        'location',
        'hnsEnabled',
        'replicationType',
        'resourceId',
      ],
      title: 'StorageAccountResource',
      type: 'object',
    },
    StorageConfig: {
      properties: {
        config: {
          anyOf: [
            {
              $ref: '#/$defs/StorageConfigBase',
            },
            {
              $ref: '#/$defs/StagedConfigDict_StorageConfigBase_',
            },
          ],
          default: {
            externalAccess: false,
            containers: [],
            accountDataContributors: [],
            containerRoleAssignments: [],
          },
          description: 'Configuration for the resource',
          title: 'Resource configuration',
        },
        kind: {
          const: 'object-store',
          default: 'object-store',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
      },
      required: ['kind'],
      title: 'StorageConfig',
      type: 'object',
    },
    StorageConfigBase: {
      description:
        'Allows provisioning storage containers within Azure storage accounts.\n\nThe `storage` capability provisions a [storage account] and enables users and\nmodules to request containers in that account.\n\n[storage account]: https://learn.microsoft.com/en-us/azure/storage/blobs/storage-blobs-introduction',
      properties: {
        externalAccess: {
          default: false,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
        containers: {
          default: [],
          description:
            'Storage containers to be created in the account.\n\n### Warning: Reserved names\n\nDo not use the reserved container names `argus*`, `dagster`, `mlflow`.\nThis may lead to unexpected behavior until we have implemented conflict\nresolution on container names.',
          items: {
            $ref: '#/$defs/StorageContainerName',
          },
          title: 'Storage containers.',
          type: 'array',
          'vsc:action': 'array',
        },
        accountDataContributors: {
          default: [],
          description:
            'Object IDs for all principals to get account level data access.',
          items: {
            description: 'AAD object ID of the group.',
            format: 'uuid',
            title: 'AAD group',
            type: 'string',
            'ui:field': 'AzureGroupSearch',
            'vsc:picker': 'aad-group',
          },
          title: 'Account data contributors.',
          type: 'array',
          'vsc:action': 'array',
        },
        containerRoleAssignments: {
          default: [],
          description: 'Container specific role assignments.',
          items: {
            $ref: '#/$defs/StorageContainerRoleAssignment',
          },
          title: 'Container role assignments.',
          type: 'array',
          'vsc:action': 'array',
        },
      },
      title: 'StorageConfigBase',
      type: 'object',
    },
    StorageContainerName: {
      maxLength: 63,
      minLength: 3,
      pattern: '^[a-z]+(?:-?[a-z0-9]+)+$',
      type: 'string',
    },
    StorageContainerResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'storage-container',
          default: 'storage-container',
          title: 'Kind',
          type: 'string',
        },
        url: {
          description: 'The URL of the storage container.',
          title: 'URL',
          type: 'string',
        },
      },
      required: ['kind', 'url', 'resourceId'],
      title: 'StorageContainerResource',
      type: 'object',
    },
    StorageContainerRole: {
      description:
        'Available storage roles to be assigned, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/role-based-access-control/built-in-roles',
      enum: [
        'Storage Blob Data Contributor',
        'Storage Blob Data Owner',
        'Storage Blob Data Reader',
      ],
      title: 'StorageContainerRole',
      type: 'string',
    },
    StorageContainerRoleAssignment: {
      properties: {
        objectId: {
          description: 'AAD entity for which the role is to be assigned.',
          format: 'uuid',
          title: 'Objectid',
          type: 'string',
        },
        containerName: {
          $ref: '#/$defs/StorageContainerName',
          description: 'The storage container this role applies to.',
        },
        role: {
          $ref: '#/$defs/StorageContainerRole',
          description: 'Role to be assigned to principal.',
        },
      },
      required: ['objectId', 'containerName', 'role'],
      title: 'StorageContainerRoleAssignment',
      type: 'object',
    },
    SubDomain: {
      maxLength: 50,
      minLength: 3,
      pattern: '^[a-z]+(?:-?[a-z0-9]+)+$',
      type: 'string',
    },
    SubscriptionID: {
      description: 'Subscription ID associated with the deployment',
      enum: [
        '15cf7065-76e8-4c4c-9a94-efcf975d03bf',
        'c842cf2d-e63c-4215-9ae5-11edd91a74cd',
        'c9643df3-75c7-4e98-801c-e0c7f5a57ab4',
        '830c0582-5aba-40b4-beae-d1f73c64173c',
        'cc08ce38-9309-4bc6-ae35-8a3f9c5bd5c5',
        '18432760-6a4b-4908-9101-1f7e19ecf7e1',
        'a70f15ec-c939-44db-a3a6-116f3688fe99',
        '9e97ce43-d54b-4b43-99b4-2af1f215b13e',
        'f2da4344-9050-420e-9536-5c3721a24cb1',
      ],
      title: 'SubscriptionID',
      type: 'string',
    },
    SubscriptionList: {
      properties: {
        subscriptions: {
          description: 'The subscriptions that belong to this cell.',
          items: {
            $ref: '#/$defs/CellSubscription',
          },
          title: 'Subscriptions',
          type: 'array',
        },
      },
      required: ['subscriptions'],
      title: 'SubscriptionList',
      type: 'object',
    },
    SupersetConfig: {
      description:
        'An open-source modern data exploration and visualization platform.',
      properties: {
        kind: {
          const: 'superset',
          default: 'superset',
          description: 'Module discriminator',
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chartVersion: {
          default: '0.10.10',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        uiPathPrefix: {
          $ref: '#/$defs/UrlPathSegment',
          default: '/',
          description: 'Route where the main UI is served.',
          title: 'UI path prefix',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the Superset UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
        globalResources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '200m',
              memory: '256Mi',
            },
            limits: {
              cpu: '500m',
              memory: '512Mi',
            },
          },
          description: 'Default Superset worker resources',
          title: 'Default Superset worker resources',
        },
        workerResources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '200m',
              memory: '512Mi',
            },
            limits: {
              cpu: '500m',
              memory: '1Gi',
            },
          },
          description: 'Default Superset worker resources',
          title: 'Default Superset worker resources',
        },
        nodeResources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '100m',
              memory: '128Mi',
            },
            limits: {
              cpu: '200m',
              memory: '256Mi',
            },
          },
          description: 'Default Superset node resources',
          title: 'Default Superset node resources',
        },
      },
      required: ['kind'],
      title: 'SupersetConfig',
      type: 'object',
    },
    Synapse: {
      properties: {
        kind: {
          const: 'synapse',
          default: 'synapse',
          description: 'Synapse Resource Kind',
          title: 'Synapse Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'Synapse Resource Name',
          title: 'Synapse Resource Name',
          type: 'string',
        },
        Repo: {
          default: 0,
          description: '# of repos for resource',
          maximum: 10,
          minimum: 0,
          title: '# of repos for resource',
          type: 'integer',
        },
        RepoFork: {
          default: false,
          description: 'Repo Fork option enabled',
          title: 'Repo Fork option enabled',
          type: 'boolean',
        },
      },
      required: ['Name'],
      title: 'Synapse',
      type: 'object',
    },
    TeamRole: {
      enum: ['developers', 'admins'],
      title: 'TeamRole',
      type: 'string',
    },
    TokenConfig: {
      properties: {
        audiences: {
          description: 'Allowed audiences for the token validation.',
          items: {
            type: 'string',
          },
          title: 'Allowed audiences',
          type: 'array',
        },
      },
      required: ['audiences'],
      title: 'TokenConfig',
      type: 'object',
    },
    UcCatalog: {
      properties: {
        kind: {
          const: 'uc_catalog',
          default: 'uc_catalog',
          description: 'UC Catalog Resource Kind',
          title: 'UC Catalog Resource Kind',
          type: 'string',
        },
        Name: {
          description: 'Catalog Name',
          title: 'Catalog Name',
          type: 'string',
        },
        Type: {
          $ref: '#/$defs/DbksBindingType',
          description: 'Catalog Type',
          title: 'Catalog Type',
        },
        Source: {
          $ref: '#/$defs/DbksCatalogSource',
          description: 'Catalog Source',
          title: 'Catalog Source',
        },
        ExternalLocation: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'External Location Name',
          title: 'External Location Name',
        },
        ExternalLocationUrl: {
          anyOf: [
            {
              format: 'uri',
              minLength: 1,
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'External Location URL',
          title: 'External Location URL',
        },
      },
      required: ['Name', 'Type', 'Source'],
      title: 'UcCatalog',
      type: 'object',
    },
    UniqueKeyType: {
      maxLength: 3,
      minLength: 3,
      pattern: '^[a-z]*$',
      type: 'string',
    },
    UpstreamCredential: {
      description:
        'UpstreamCredential specifies the type of credential to use for the upstream.',
      enum: ['azure:msgraph', 'azure:cognitiveservices'],
      title: 'UpstreamCredential',
      type: 'string',
    },
    UpstreamRef: {
      description:
        'UpstreamRef is a reference to an upstream service to forward matched requests to.',
      properties: {
        name: {
          description: 'Name of the upstream.',
          title: 'Name',
          type: 'string',
        },
        host: {
          description: 'Host of the upstream.',
          title: 'Host',
          type: 'string',
        },
        port: {
          description: 'Port of the upstream.',
          title: 'Port',
          type: 'integer',
        },
      },
      required: ['name', 'host', 'port'],
      title: 'UpstreamRef',
      type: 'object',
    },
    UrlPathSegment: {
      pattern: '^\\/[a-zA-Z0-9_\\.\\/\\-]*?$',
      type: 'string',
    },
    UserEnvironment: {
      properties: {
        image: {
          description:
            'Docker image to be used when spawning this environment.',
          title: 'Base image',
          type: 'string',
        },
        tag: {
          default: 'latest',
          description: 'User image tag to be used',
          title: 'Image tag.',
          type: 'string',
        },
        displayName: {
          description: 'User image display name to be used',
          title: 'Display name.',
          type: 'string',
        },
        description: {
          description: 'User image description to be used',
          title: 'Description',
          type: 'string',
        },
      },
      required: ['image'],
      title: 'UserEnvironment',
      type: 'object',
    },
    Workflow: {
      description: 'Workflow configured on a code location.',
      enum: ['github-flow'],
      title: 'Workflow',
      type: 'string',
    },
    WorkspaceEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        output: {
          $ref: '#/$defs/WorkspaceReport',
        },
        input: {
          $ref: '#/$defs/WorkspaceManifest',
        },
        policies: {
          $ref: '#/$defs/PolicyReport',
          title: 'Policies.',
        },
      },
      required: ['stackVersion', 'output', 'input', 'policies'],
      title: 'WorkspaceEntry',
      type: 'object',
    },
    WorkspaceManifest: {
      description: 'Manifest for a workspace in devhub.',
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'WorkspaceManifest',
          default: 'WorkspaceManifest',
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/ManifestMetadata',
          description: 'Metadata for the entity.',
          title: 'Metadata',
        },
        spec: {
          $ref: '#/$defs/WorkspaceSpec',
          description: 'Workspace specification.',
          title: 'Workspace specification',
        },
      },
      required: ['spec', 'metadata', 'apiVersion', 'kind'],
      title: 'WorkspaceManifest',
      type: 'object',
    },
    WorkspaceReport: {
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          default: 'core.argus.ai/v1alpha1',
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'WorkspaceCatalogEntry',
          default: 'WorkspaceCatalogEntry',
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/ManifestMetadata',
          description: 'Basic metadata about the workspace.',
          title: 'Workspace metadata.',
        },
        identity: {
          $ref: '#/$defs/IdentityInfo',
          description: 'Identity information for the workspace.',
          title: 'Workspace identity.',
        },
        application: {
          $ref: '#/$defs/ApplicationInfo',
          description: 'Application information for the workspace.',
          title: 'Workspace application.',
        },
        devops: {
          anyOf: [
            {
              $ref: '#/$defs/DevOpsOutput',
            },
            {
              type: 'null',
            },
          ],
          default: null,
        },
        githubTeams: {
          description: 'GitHub teams associated with the workspace.',
          items: {
            $ref: '#/$defs/GitHubTeamInfo',
          },
          title: 'GitHub teams.',
          type: 'array',
        },
        resources: {
          description: 'Azure resources provisioned for the workspace.',
          items: {
            description: 'Any Resource',
            discriminator: {
              mapping: {
                acr: '#/$defs/AcrResource',
                'cognitive-deployment': '#/$defs/CognitiveDeploymentsResource',
                'cognitive-services': '#/$defs/CognitiveAccountResource',
                'key-vault': '#/$defs/KeyVaultResource',
                'kusto-cluster': '#/$defs/KustoClusterResource',
                mysql: '#/$defs/MysqlServerResource',
                'mysql-db': '#/$defs/MysqlDatabaseResource',
                postgres: '#/$defs/PostgresServerResource',
                'postgres-db': '#/$defs/PostgresDatabaseResource',
                'resource-group': '#/$defs/ResourceGroupResource',
                'storage-account': '#/$defs/StorageAccountResource',
                'storage-container': '#/$defs/StorageContainerResource',
              },
              propertyName: 'kind',
            },
            oneOf: [
              {
                $ref: '#/$defs/AcrResource',
              },
              {
                $ref: '#/$defs/StorageAccountResource',
              },
              {
                $ref: '#/$defs/KeyVaultResource',
              },
              {
                $ref: '#/$defs/PostgresServerResource',
              },
              {
                $ref: '#/$defs/StorageContainerResource',
              },
              {
                $ref: '#/$defs/PostgresDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlServerResource',
              },
              {
                $ref: '#/$defs/ResourceGroupResource',
              },
              {
                $ref: '#/$defs/CognitiveAccountResource',
              },
              {
                $ref: '#/$defs/CognitiveDeploymentsResource',
              },
              {
                $ref: '#/$defs/KustoClusterResource',
              },
            ],
            title: 'Any Resource',
          },
          title: 'Workspace resources.',
          type: 'array',
        },
      },
      required: ['kind', 'metadata', 'identity', 'application', 'resources'],
      title: 'WorkspaceReport',
      type: 'object',
    },
    WorkspaceSpec: {
      properties: {
        owner: {
          description:
            'AAD object ID or display name for the group owning the entity.',
          format: 'uuid',
          title: 'Owner group',
          type: 'string',
          'ui:field': 'AzureGroupSearch',
          'vsc:picker': 'aad-group',
        },
        primaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description: 'AAD object id or email of the primary contact person.',
          title: 'Primary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        secondaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description:
            'AAD object id or email of the secondary contact person.',
          title: 'Secondary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        uniqueKey: {
          $ref: '#/$defs/UniqueKeyType',
          description: 'A unique identifier for the entity.',
          title: 'Unique key.',
          'ui:disabled': true,
        },
        lifecycle: {
          $ref: '#/$defs/argus__provisioning__models__common__registry__Lifecycle',
          default: 'production',
          description: 'Lifecycle of the entity.',
          title: 'Entity lifecycle stage.',
        },
        featureFlags: {
          default: [],
          description:
            'Feature flags allow op-ting into experimental functionality in the stack',
          items: {
            $ref: '#/$defs/FeatureFlag',
          },
          title: 'Feature flags.',
          type: 'array',
          'vsc:action': 'array',
        },
        location: {
          $ref: '#/$defs/AzureRegion',
          default: 'westeurope',
          description: 'Azure region where resources should be provisioned.',
          title: 'Location',
        },
        devOps: {
          anyOf: [
            {
              $ref: '#/$defs/AzureDevOpsProject',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Azure DevOps project, where workspace presence is to be installed.',
          title: 'Azure DevOps project',
        },
        devTeams: {
          description:
            'List of development teams that should have access to the workspace.',
          items: {
            $ref: '#/$defs/GitHubTeamConfig',
          },
          title: 'Development teams',
          type: 'array',
        },
        linkedTeams: {
          description:
            'List of teams that are assigned permissions on workspace repositories.',
          items: {
            $ref: '#/$defs/LinkedGitHubTeamConfig',
          },
          title: 'Linked GitHub teams',
          type: 'array',
        },
      },
      required: ['owner', 'primaryContact', 'secondaryContact', 'uniqueKey'],
      title: 'WorkspaceSpec',
      type: 'object',
    },
    argus__provisioning__models__common__registry__Lifecycle: {
      enum: ['experimental', 'development', 'production', 'end-of-life'],
      title: 'Lifecycle',
      type: 'string',
    },
    dsp_deployment__models__manifests___spec_choices__Lifecycle: {
      description: 'Lifecycle of the model',
      enum: ['development', 'production', 'staging', 'testing'],
      title: 'Lifecycle',
      type: 'string',
    },
  },
  title: 'CatalogEntries',
  oneOf: [
    {
      $ref: '#/$defs/DspManifest',
    },
    {
      $ref: '#/$defs/ProductManifest',
    },
    {
      $ref: '#/$defs/WorkspaceManifest',
    },
    {
      $ref: '#/$defs/PlatformManifest',
    },
    {
      $ref: '#/$defs/GitHubEnterpriseManifest',
    },
    {
      $ref: '#/$defs/ProductCellManifest',
    },
    {
      $ref: '#/$defs/PlatformEntry',
    },
    {
      $ref: '#/$defs/InfrastructureCellEntry',
    },
    {
      $ref: '#/$defs/GatewayEntry',
    },
    {
      $ref: '#/$defs/ProductEntry',
    },
    {
      $ref: '#/$defs/WorkspaceEntry',
    },
    {
      $ref: '#/$defs/GitHubEnterpriseEntry',
    },
    {
      $ref: '#/$defs/PolicyReportEntry',
    },
    {
      $ref: '#/$defs/ResourcesReportEntry',
    },
    {
      $ref: '#/$defs/InstanceEntry',
    },
    {
      $ref: '#/$defs/InstanceCellEntry',
    },
    {
      $ref: '#/$defs/InstanceRegistryReport',
    },
    {
      $ref: '#/$defs/RegistryAgentReport',
    },
    {
      $ref: '#/$defs/CellClusterReport',
    },
    {
      $ref: '#/$defs/InstanceCellReport',
    },
    {
      $ref: '#/$defs/DeployedInstanceLayout',
    },
    {
      $ref: '#/$defs/InstanceResources',
    },
    {
      $ref: '#/$defs/InstanceGroups',
    },
  ],
  type: 'object',
} as const;
